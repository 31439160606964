import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import * as React from "react";
import {
  Datagrid,
  EditButton,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput
} from "react-admin";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { useContext } from "react";

const cmRoomRateFilters = [
  <TextInput
    source="cm_client_room_rate_name"
    label="Client Room Rate Name"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    source="cm_client_id"
    reference="cm_clients"
    sort={{ field: "name", order: "ASC" }}
    label="Client"
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

const CMRoomRateList = (props) => {
  const {authInfo} = useContext(AuthInfoContext);

  return (
    <List
      filters={cmRoomRateFilters}
      filterDefaultValues={{ tenant_id: authInfo.tenant.id }}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid>
        <ReferenceField
          label="Client"
          source="cm_client_id"
          reference="cm_clients"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Room Type"
          source="room_type_id"
          reference="room_types"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="cm_client_room_rate_name" label="Client Room Rate Name" />
        <EditButton
          style={{ float: "right" }}
          basePath="/cm_room_type_rate_maps"
        />
      </Datagrid>
    </List>
  );
};

export default CMRoomRateList;
