import React from "react";
import {
  FormWithRedirect,
  TextInput,
  SelectInput,
  required,
  minLength,
  maxLength,
  email,
  useTranslate,
  ReferenceInput,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Box, Button } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";

import CustomSaveButton from './CustomSaveButton';
import { userApi } from '../../api/userApi';
import { bleudineUserApi } from "../../api/bleudineUserApi";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { useContext } from "react";


const validateFirstName = [required(), minLength(2), maxLength(25)];
const validateEmail = [required(), email()];

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const UserForm = props => {
  const {authInfo} = useContext(AuthInfoContext);
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const { source, ...rest } = props;
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmDelete = (id) => {
    bleudineUserApi.deleteUser(id)
    .then((ret) => {
      authInfo.tenant ? redirect(`/users`): redirect(`/users?role=manager`);
      notify("User deleted");
      },
      (error) => {
				notify(error);
			});
  };

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
								<Grid item xs={12} className={classes.formGrid} style={{ display: "none" }}>
                  <TextInput
                    className={classes.formInput}
                    source="tenant_id"
                    label="Tenant ID"
                    fullWidth
                    defaultValue={authInfo.tenant ? authInfo.tenant.id : authInfo.tenant_group_id}
                    validate={[required()]}
                  />
                  <TextInput
                    className={classes.formInput}
                    resource="users"
                    source="id"
                    label="ID"
                    fullWidth
                  />
                </Grid>
								<Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    fullWidth
										label="Role"
                    source="role"
                    reference="e_user_roles"
										validate={[required()]}
                    filter={authInfo.tenant ? { comment: [ "Frontdesk", "Housekeeper"] } :  { comment: ["Manager"] }}
                  >
                    <SelectInput optionText="comment" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="users"
                    source="email"
                    type="email"
                    validate={validateEmail}
                    label="ra.column.email"
                    disabled={props.record.id !== undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="users"
                    source="first_name"
                    validate={validateFirstName}
                    label="ra.column.first_name"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="users"
                    source="last_name"
                    label="ra.column.last_name"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ReferenceInput
                    className={classes.formInput}
                    label="Status"
                    source="status"
                    reference="e_user_status"
                    validate={[required()]}
                    filter={{ comment: ["Active", "Inactive"] }}
                  >
                    <SelectInput optionText="comment" />
                  </ReferenceInput>
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CustomSaveButton {...formProps} />
                {
                    <Button
                    style={{ color: "red" }}
                    variant="outline"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={handleOpenDelete}
                  >
                    Delete
                  </Button> 
                }
              </Box>
              <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirm Delete?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please confirm to delete
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleCloseDelete()} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleConfirmDelete(formProps.record.mapped_id)}
                    color="primary"
                    autoFocus
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  )
}

export default UserForm;