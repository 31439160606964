import { handleError } from "./apiHelper";
import { executeAsync } from "./hasura";



export async function createCmSyncTask({ tenantId, action }) {
    const gql = `mutation CreateCmSyncTask($action:String!, $tenantId:uuid!) {
        create_cm_sync_task(action:$action, tenant_id: $tenantId){
            task_ids
        }
    }`;
    const { data, error } = await executeAsync({ tenantId, action }, gql)
    if (error) {
        throw error;
    }
    return data.create_cm_sync_task;
}

export async function listIncompleteTaskForAction({ tenantId, action }) {
    const gql = `query FindCmSyncTask($tenantId: uuid!, $action: String!, $status:[String!]) {
        cm_sync_task(where:{tenant_id:{_eq: $tenantId}, task_name: {_eq: $action}, status:{_nin:$status}}){
            id
            status
        }
    }`;

    const { data, error } = await executeAsync({ tenantId, action, status: ["Complete", "Error"] }, gql);
    return data.cm_sync_task;
}

export async function enqueueJob({ tenantId, action }) {

    const result = await listIncompleteTaskForAction({ tenantId, action });
    if (result && result.length) { 
        throw new Error("You already have an pending job for this action, navigate to Channel Manager > Jobs to view");
    }
    return await createCmSyncTask({tenantId, action});
}