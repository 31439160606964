import * as React from 'react';
import { useContext } from 'react';
import { Layout, Loading } from 'react-admin';
import { useSelector } from 'react-redux';
import { AuthInfoContext } from '../../hooks/useAuthInfo';
import AppBar from './AppBar';
import Menu from './Menu';
import { darkTheme, lightTheme } from './themes';

export default (props) => {
    const {authInfo} = useContext(AuthInfoContext);
    const theme = useSelector((state) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );
    return authInfo ? <Layout {...props} appBar={AppBar} menu={Menu} theme={theme}/>
        : <Loading loadingPrimary={"Please wait"} />;
};
