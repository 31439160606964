import { Box, Button, Card, CardContent, InputAdornment, Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import PageviewIcon from "@material-ui/icons/Pageview";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import {
  DataProviderContext,
  FormDataConsumer,
  FormWithRedirect,
  maxValue,
  minValue,
  number,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useNotify,
  useTranslate
} from "react-admin";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { invoiceStatus } from "../../models/invoiceStatus";
import { formatter } from "../../utils/formatter";


const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subCardWrap: {
    minHeight: 260,
  },
}));

const DialogTitle = (props) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const PaymentModal = ({
  openPayment,
  setOpenPayment,
  selectedPayment,
  availableService,
  booking,
  selectedInvoice,
  refreshBooking,
  refreshSelectedInvoice,
}) => {
  const {authInfo} = useContext(AuthInfoContext);
  
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);

  const addPayment = (formData) => {
    var request = {
      tenant_id: authInfo.tenant.id,
      invoice_id: selectedInvoice.id,
      payment_type: formData.payment_type,
      amount: formData.amount,
      remarks: formData.remarks,
      status: "Success",
    };
    dataProvider
      .create("payments", { data: request })
      .then(({ data }) => {
        let paidAmount =
          selectedInvoice.payments
            .filter((record) => record.status == "Success")
            .reduce((sum, record) => sum + record.amount, 0) + formData.amount;

        dataProvider
          .update("invoices", {
            id: selectedInvoice.id,
            data: {
              status:
                paidAmount == selectedInvoice.total ? "Paid" : "Partially_Paid",
            },
          })
          .then(({ data }) => {
            if (paidAmount == selectedInvoice.total) {
              booking.orders
                .filter((record) => record.invoice_id == selectedInvoice.id)
                .forEach((record) => {
                  dataProvider.update("orders", {
                    id: record.id,
                    data: {
                      status: "Paid",
                    },
                  });
                });
            }
            refreshBooking();
            refreshSelectedInvoice(selectedInvoice.id);
            setOpenPayment(false);
            notify("Payment created");
          })
          .catch((error) => {
            notify(error);
          });
      })
      .catch((error) => {
        notify(error);
      });
  };

  // const updatePayment = (process, formData) => {
  //   var request = {};
  //   if (process == "update") {
  //     var selected_service = availableService.filter(
  //       (fitem) => fitem.id === formData.service_id
  //     )[0];

  //     request.accommodation_id = formData.accommodation_id;
  //     request.service_id = formData.service_id;
  //     request.qty = formData.qty;
  //     request.price = selected_service.price;
  //     request.service_charge = selected_service.service_charge;
  //     request.total_price =
  //       (selected_service.price +
  //         selected_service.price * selected_service.service_charge) *
  //       formData.qty;
  //     request.booking_id = booking.id;
  //     request.description = selected_service.name;
  //     request.order_type = "Service";
  //     request.status = "New";
  //   }
  //   if (process == "cancel") {
  //     request.status = "Cancelled";
  //   }

  //   dataProvider
  //     .update("orders", {
  //       id: selectedPayment.id,
  //       data: { ...request },
  //     })
  //     .then(({ data }) => {
  //       setOpenPayment(false);
  //       notify("Order updated");
  //     })
  //     .catch((error) => {
  //       notify(error);
  //     });
  // };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      onClose={() => setOpenPayment(false)}
      aria-labelledby="customized-dialog-title"
      open={openPayment}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenPayment(false)}
      >
        {selectedPayment != null
          ? "Edit Payment " + selectedPayment.payment_no
          : "Add Payment"}
      </DialogTitle>
      <FormWithRedirect
        onSubmit={() => {}}
        record={selectedPayment}
        render={(formProps) => (
          <div>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    fullWidth
                    label="Payment Type"
                    source="payment_type"
                    reference="e_payment_types"
                    validate={[required()]}
                  >
                    <SelectInput optionText="comment" />
                  </ReferenceInput>
                </Grid>

                <Grid item xs={12} className={classes.formGrid}>
                  <NumberInput
                    className={classes.formInput}
                    fullWidth
                    label="Amount"
                    source="amount"
                    min={1}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{authInfo.tenant.locale.currency_code}</InputAdornment>,
                    }}
                    validate={[
                      required(),
                      number(),
                      minValue(1),
                      maxValue(
                        selectedInvoice.total -
                          selectedInvoice.payments
                            .filter((record) => record.status == "Success")
                            .reduce((sum, record) => sum + record.amount, 0)
                      ),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    source="remarks"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  selectedPayment == null ? (
                    <SaveButton
                      {...formProps}
                      variant="contained"
                      color="primary"
                      className={classes.formInput}
                      onSave={() => addPayment(formData)}
                    />
                  ) : null
                }
              </FormDataConsumer>
              {/* <FormDataConsumer>
                {({ formData, ...rest }) =>
                  selectedPayment?.status == "New" ? (
                    <SaveButton
                      {...formProps}
                      variant="contained"
                      color="primary"
                      label="Update"
                      className={classes.formInput}
                      onSave={() => updatePayment("update", formData)}
                    />
                  ) : null
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  selectedPayment?.status == "New" ? (
                    <SaveButton
                      {...formProps}
                      style={{ background: "red" }}
                      variant="contained"
                      color="primary"
                      label="Cancel"
                      icon={<ClearIcon />}
                      className={classes.formInput}
                      onSave={() => updatePayment("cancel", formData)}
                    />
                  ) : null
                }
              </FormDataConsumer> */}
            </DialogActions>
          </div>
        )}
      />
    </Dialog>
  );
};

const InvoiceForm = (props) => {
  const {authInfo} = useContext(AuthInfoContext);
  
  const translate = useTranslate();
  const { source, ...rest } = props;
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);
  const [availableBooking, setAvailableBooking] = React.useState([]);
  const [availableService, setAvailableService] = React.useState([]);

  const [selectedInvoiceSubtotal, setSelectedInvoiceSubtotal] =
    React.useState(null);
  const [selectedInvoiceTax, setSelectedInvoiceTax] = React.useState(null);
  const [selectedInvoiceTotal, setSelectedInvoiceTotal] = React.useState(null);
  const [openPayment, setOpenPayment] = React.useState(false);
  const [selectedOrderIds, setSelectedOrderIds] = React.useState([]);
  const [selectedPayment, setSelectedPayment] = React.useState(null);

  useEffect(() => {
    var request = {
      tenant_id: authInfo.tenant.id,
    };
    dataProvider
      .getList("services", { filter: { ...request } })
      .then(({ data }) => {
        setAvailableService(data);
      })
      .catch((error) => {
        notify(error);
      });
    dataProvider
      .getList("bookings", { filter: { ...request } })
      .then(({ data }) => {
        setAvailableBooking(data);
      })
      .catch((error) => {
        notify(error);
      });
  }, []);

  const addInvoice = (formData) => {
    let booking = availableBooking.filter((item) => {
      return item.id == formData.booking_id;
    })[0];

    let isValid = true;
    if (selectedOrderIds.length <= 0) {
      notify("Please select order to create invoice");
      isValid = false;
    }

    if (isValid) {
      var request = {
        tenant_id: authInfo.tenant.id,
        booking_id: booking.id,
        subtotal: selectedInvoiceSubtotal,
        tax: selectedInvoiceTax,
        total: selectedInvoiceTotal,
        status: "New",
      };
      dataProvider
        .create("invoices", { data: request })
        .then(({ data }) => {
          var orderIndex = 0;

          selectedOrderIds.forEach((sid) => {
            orderIndex += 1;
            dataProvider
              .update("orders", {
                id: sid,
                data: { status: "Invoiced", invoice_id: data.id },
              })
              .then(({ data }) => {
                if (orderIndex == selectedOrderIds.length) {
                  refreshBooking();
                }
              });
          });

          notify("Invoice created");
        })
        .catch((error) => {
          notify(error);
        });
    }
  };

  const updateInvoice = (process, formData) => {
    let booking = availableBooking.filter((item) => {
      return item.id == formData.booking_id;
    })[0];

    var request = {};
    if (process == "update") {
      var selected_service = availableService.filter(
        (fitem) => fitem.id === formData.service_id
      )[0];

      request.accommodation_id = formData.accommodation_id;
      request.service_id = formData.service_id;
      request.qty = formData.qty;
      request.price = selected_service.price;
      request.service_charge = selected_service.service_charge;
      request.total_price =
        selected_service.price * selected_service.service_charge * formData.qty;
      request.booking_id = booking.id;
      request.description = selected_service.name;
      request.invoice_type = "Service";
      request.status = "New";
    }
    if (process == "cancel") {
      request.status = "Cancelled";
    }

    dataProvider
      .update("invoices", {
        id: props.record?.id,
        data: { ...request },
      })
      .then(({ data }) => {
        if (process == "cancel") {
          props.record?.ordersIds.forEach((sid) => {
            dataProvider.update("orders", {
              id: sid,
              data: { status: "New", invoice_id: null },
            });
          });
        }

        refreshBooking();
        notify("Invoice updated");
      })
      .catch((error) => {
        notify(error);
      });
  };

  const refreshBooking = () => {
    window.location.href = "/invoices";
  };

  const refreshSelectedInvoice = (invoiceId) => {
    window.location.reload();
  };

  const orderColumns = [
    { field: "order_no", headerName: "Order No", width: 150 },
    { field: "room", headerName: "Room", width: 120 },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      sortable: false,
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      sortable: false,
    },
    {
      field: "service_charge",
      headerName: "Service Charge",
      width: 180,
      sortable: false,
    },
    {
      field: "qty",
      headerName: "Quantity",
      width: 120,
      sortable: false,
    },
    {
      field: "total_price",
      headerName: "Total Price",
      width: 150,
      sortable: false,
    },
    {
      field: "created_at",
      headerName: "Order Date",
      width: 200,
      sortable: false,
    },
  ];

  const orderRows = (booking_id) => {
    var booking = availableBooking.filter((item) => item.id == booking_id);
    var orders = [];

    if (booking.length > 0) {
      booking = booking[0];
      orders = booking.orders?.reduce(function (result, option) {
        if (
          (Object.keys(props.record).length == 0 && option.status == "New") ||
          (Object.keys(props.record).length != 0 &&
            props.record?.ordersIds.includes(option.id))
        ) {
          return result.concat({
            id: option.id,
            order_no: formatter.orderNo(option.order_no),
            room: option.accommodation.room.name,
            description: option.description,
            price: option.price,
            service_charge: option.service_charge * option.price,
            qty: option.qty,
            total_price: option.total_price,
            created_at: moment(option.created_at).format(
              "DD MMM YYYY hh:mm:ss A"
            ),
          });
        }
        return result;
      }, []);
    }
    return orders;
  };
  const paymentColumns = [
    { field: "payment_no", headerName: "Payment No", width: 180 },
    { field: "payment_type", headerName: "Payment Type", width: 180 },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      sortable: false,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 200,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: false,
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      width: 200,
      sortable: false,
    },
  ];

  const paymentRows = props.record?.payments?.map(function (option) {
    return {
      id: option.id,
      payment_no: formatter.paymentNo(option.payment_no),
      payment_type: option.e_payment_type.comment,
      amount: formatter.formatMoney(
        option.amount,
        authInfo.tenant.locale.currency_code,
        authInfo.tenant.locale.precision
      ),
      remarks: option.remarks,
      status: option.e_payment_status.comment,
      payment_date: moment(option.created_at).format("DD MMM YYYY hh:mm:ss A"),
    };
  });

  const selectOrder = (booking_id, ids) => {
    let price = 0;
    let tax = 0;
    let total_price = 0;
    orderRows(booking_id).forEach((obj) => {
      if (ids.includes(obj.id)) {
        price += obj.price * obj.qty;
        tax += obj.service_charge * obj.qty;
        total_price += obj.total_price;
      }
    });

    setSelectedOrderIds(ids);
    setSelectedInvoiceSubtotal(price);
    setSelectedInvoiceTax(tax);
    setSelectedInvoiceTotal(total_price);
  };

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <PaymentModal
              openPayment={openPayment}
              setOpenPayment={setOpenPayment}
              selectedPayment={selectedPayment}
              availableService={availableService}
              booking={props.record?.booking}
              selectedInvoice={props.record}
              refreshBooking={refreshBooking}
              refreshSelectedInvoice={refreshSelectedInvoice}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.formGrid}>
                  <SelectInput
                    className={classes.formInput}
                    fullWidth
                    label="Booking No"
                    source="booking_id"
                    choices={availableBooking?.map(function (item) {
                      return {
                        id: item.id,
                        name: formatter.bookingNo(item.booking_no),
                      };
                    })}
                    options={{
                      InputProps: { autoComplete: "no" },
                    }}
                    validate={[required()]}
                    disabled={Object.keys(props.record).length != 0}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Orders
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.formGrid}
                  style={{ marginBottom: 10 }}
                >
                  <div style={{ height: 400, width: "100%" }}>
                    <FormDataConsumer>
                      {({ formData, ...rest }) => {
                        return (
                          <DataGrid
                            rows={orderRows(formData.booking_id)}
                            columns={orderColumns}
                            checkboxSelection={
                              Object.keys(props.record).length == 0
                            }
                            disableSelectionOnClick
                            rowsPerPageOptions={[]}
                            selectionModel={selectedOrderIds}
                            onSelectionModelChange={(ids) => {
                              selectOrder(formData.booking_id, ids);
                            }}
                          />
                        );
                      }}
                    </FormDataConsumer>
                  </div>
                </Grid>
              </Grid>
              {Object.keys(props.record).length != 0 ? <br /> : null}
              {Object.keys(props.record).length != 0 ? <br /> : null}
              {Object.keys(props.record).length != 0 ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.formGrid}>
                    <Typography
                      variant="h6"
                      className={classes.textBold}
                      gutterBottom
                    >
                      Payments
                      {props.record?.status != "Paid" ? (
                        <Button
                          style={{ float: "right", color: "#4f3cc9" }}
                          variant="outline"
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            setOpenPayment(true);
                          }}
                        >
                          Add
                        </Button>
                      ) : null}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classes.formGrid}
                    style={{ marginBottom: 10 }}
                  >
                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        rows={paymentRows}
                        columns={paymentColumns}
                        checkboxSelection={
                          Object.keys(props.record).length == 0
                        }
                        disableSelectionOnClick
                        rowsPerPageOptions={[]}
                        selectionModel={selectedOrderIds}
                        onSelectionModelChange={(ids) => {
                          selectOrder(ids);
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              ) : null}

              <br />
              <br />
              <Grid container spacing={2}>
                <Grid item xs={6} className={classes.formGrid}>
                  <Typography gutterBottom>Subtotal</Typography>
                </Grid>
                <Grid item xs={6} className={classes.formGrid} align="right">
                  {Object.keys(props.record).length != 0
                    ? formatter.formatMoney(
                        props.record?.subtotal,
                        authInfo.tenant.locale.currency_code,
                        authInfo.tenant.locale.precision
                      )
                    : selectedInvoiceSubtotal
                    ? formatter.formatMoney(
                        selectedInvoiceSubtotal,
                        authInfo.tenant.locale.currency_code,
                        authInfo.tenant.locale.precision
                      )
                    : "-"}
                </Grid>
                <Grid item xs={6} className={classes.formGrid}>
                  <Typography gutterBottom>Tax</Typography>
                </Grid>
                <Grid item xs={6} className={classes.formGrid} align="right">
                  {Object.keys(props.record).length != 0
                    ? formatter.formatMoney(
                        props.record?.tax,
                        authInfo.tenant.locale.currency_code,
                        authInfo.tenant.locale.precision
                      )
                    : selectedInvoiceTax
                    ? formatter.formatMoney(
                        selectedInvoiceTax,
                        authInfo.tenant.locale.currency_code,
                        authInfo.tenant.locale.precision
                      )
                    : "-"}
                </Grid>
                <Grid item xs={6} className={classes.formGrid}>
                  <Typography gutterBottom>Amount</Typography>
                </Grid>
                <Grid item xs={6} className={classes.formGrid} align="right">
                  {Object.keys(props.record).length != 0
                    ? formatter.formatMoney(
                        props.record?.total,
                        authInfo.tenant.locale.currency_code,
                        authInfo.tenant.locale.precision
                      )
                    : selectedInvoiceTotal
                    ? formatter.formatMoney(
                        selectedInvoiceTotal,
                        authInfo.tenant.locale.currency_code,
                        authInfo.tenant.locale.precision
                      )
                    : "-"}
                </Grid>
                {Object.keys(props.record).length != 0 ? (
                  <Grid item xs={6} className={classes.formGrid}>
                    <Typography gutterBottom>Paid</Typography>
                  </Grid>
                ) : null}
                {Object.keys(props.record).length != 0 ? (
                  <Grid item xs={6} className={classes.formGrid} align="right">
                    {formatter.formatMoney(
                      props.record?.payments
                        .filter((record) => record.status == "Success")
                        .reduce((sum, record) => sum + record.amount, 0),
                      authInfo.tenant.locale.currency_code,
                      authInfo.tenant.locale.precision
                    )}
                  </Grid>
                ) : null}
                {Object.keys(props.record).length != 0 ? (
                  <Grid item xs={6} className={classes.formGrid}>
                    <Typography gutterBottom>Due Amount</Typography>
                  </Grid>
                ) : null}
                {Object.keys(props.record).length != 0 ? (
                  <Grid item xs={6} className={classes.formGrid} align="right">
                    {Object.keys(props.record).length != 0
                      ? formatter.formatMoney(
                          props.record?.total -
                            props.record.payments
                              .filter((record) => record.status == "Success")
                              .reduce((sum, record) => sum + record.amount, 0),
                          authInfo.tenant.locale.currency_code,
                          authInfo.tenant.locale.precision
                        )
                      : selectedInvoiceTotal
                      ? formatter.formatMoney(
                          selectedInvoiceTotal,
                          authInfo.tenant.locale.currency_code,
                          authInfo.tenant.locale.precision
                        )
                      : "-"}
                  </Grid>
                ) : null}
                {Object.keys(props.record).length != 0 ? (
                  <Grid item xs={6} className={classes.formGrid}>
                    <Typography gutterBottom>Status</Typography>
                  </Grid>
                ) : null}
                {Object.keys(props.record).length != 0 ? (
                  <Grid
                    item
                    xs={6}
                    className={classes.formGrid}
                    style={{ textAlign: "right" }}
                  >
                    <Chip
                      label={props.record?.e_invoice_status.comment}
                      style={{
                        color: "white",
                        background: invoiceStatus.getColor(
                          props.record?.status
                        ),
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>

            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <div>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      selectedOrderIds.length > 0 ||
                      Object.keys(props.record).length != 0 ? (
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<PageviewIcon />}
                          className={classes.formInput}
                          //onClick={() => setOpenInvoiceDetail(true)}
                        >
                          View
                        </Button>
                      ) : null
                    }
                  </FormDataConsumer>
                  &nbsp;
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      Object.keys(props.record).length == 0 ? (
                        <SaveButton
                          {...formProps}
                          variant="contained"
                          color="primary"
                          className={classes.formInput}
                          onSave={() => addInvoice(formData)}
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                </div>
                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    props.record?.status == "New" ? (
                      <SaveButton
                        {...formProps}
                        style={{ background: "red" }}
                        variant="contained"
                        color="primary"
                        label="Cancel"
                        icon={<ClearIcon />}
                        className={classes.formInput}
                        onSave={() => updateInvoice("cancel", formData)}
                      />
                    ) : null
                  }
                </FormDataConsumer>
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default InvoiceForm;
