import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  TextField,
  Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect } from "react";
import {
  Title,
  useNotify
} from "react-admin";
import { reportApi } from "../../api/reportApi";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { getFirstLastDayInCurrentMonth } from "../../utils/date";
import { formatter } from "../../utils/formatter";

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },

  centerContent: {
    marginBottom: 44,
  },
  mainGrid: {
    marginTop: theme.spacing(1),
  },
}));

const AccountingReport = () => {
  const notify = useNotify();
  const classes = useStyles();

  const [initialCheckInDate, initialCheckOutDate] =
    getFirstLastDayInCurrentMonth();

  const [occupencyData, setAccountingData] = React.useState([]);
  const [checkInDate, setCheckInDate] = React.useState(initialCheckInDate);
  const [checkOutDate, setCheckOutDate] = React.useState(initialCheckOutDate);

  const {authInfo} = useContext(AuthInfoContext);
  
  const currency = authInfo.tenant.locale.currency_code;

  useEffect(() => {
    searchReport();
  }, []);

  const searchReport = () => {
    reportApi
      .getAccountingReport(authInfo.tenant.id, checkOutDate, checkInDate)
      .then(({ data }) => setAccountingData(data.get_accounting_report))
      .catch((error) => notify(error));
  };

  return (
    <div>
      <Title title={"Accounting"} />
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 12,
        }}
      >
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <TextField
            style={{
              marginTop: 12,
            }}
            type="date"
            variant="filled"
            className={classes.formInput}
            value={checkInDate}
            onChange={(e) => setCheckInDate(e.target.value)}
            fullWidth
            label="Start Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <TextField
            type="date"
            variant="filled"
            className={classes.formInput}
            value={checkOutDate}
            onChange={(e) => {
              setCheckOutDate(e.target.value);
            }}
            fullWidth
            label="End Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <Button
            style={{
              marginTop: 12,
              marginRight: 5,
            }}
            onClick={searchReport}
            variant="contained"
            color="primary"
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12}>
          {reportList(occupencyData)}
        </Grid>
      </Grid>
    </div>
  );

  function reportList(occupencyData) {
    if (
      occupencyData == null ||
      occupencyData == undefined ||
      occupencyData.length == 0
    )
      return <div></div>;

    const {
      total_cash_amount,
      total_credit_card_amount,
      total_refund_amount,
      total_room_revenue,
      total_service_charge,
      total_service_revenue,
    } = occupencyData[0];

    return (
      <Container maxWidth="lg" component="main">
        <Grid
          container
          spacing={5}
          alignItems="flex-end"
          className={classes.mainGrid}
        >
          {BuildCard("Total cash", total_cash_amount)}
          {BuildCard("Total credit card", total_credit_card_amount)}
          {BuildCard("Total refund", total_refund_amount)}
          {BuildCard("Total room revenue", total_room_revenue)}
          {BuildCard("Total service charge", total_service_charge)}
          {BuildCard("Total service revenue", total_service_revenue)}
        </Grid>
      </Container>
    );
  }

  function BuildCard(cardTitle, cardValue) {
    return (
      <Grid item xs={12} sm={12} md={3}>
        <Card>
          <CardHeader
            title={cardTitle}
            titleTypographyProps={{
              align: "center",
              component: "h3",
              variant: "h6",
              color: "#000000",
            }}
          />
          <CardContent className={classes.centerContent}>
            <div className={classes.cardPricing}>
              <Typography component="h3" variant="h5" color="textPrimary">
                {formatter.formatMoney(
                  cardValue,
                  authInfo.tenant.locale.currency_code,
                  authInfo.tenant.locale.precision
                )}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  }
};

export default AccountingReport;
