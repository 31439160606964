import { apiConfig } from "../api/apiConfig";
import { bleudineMerchantApi } from "../api/bleudineMerchantApi";
import { getLocaleByCountryCode } from "../api/localeApi";
import { tenantGroupApi } from "../api/tenantGroupApi";
import { checkDbAuth } from "../hooks/useAuthInfo";

const redirectToLogin = async () => {
    window.localStorage.removeItem("_code_verifier");
    const domain = window.location.origin;
    const redirectUri = `${domain}/auth-callback`;
    const code_verifier = window.crypto.randomUUID();
    window.localStorage.setItem("_code_verifier", code_verifier);
    let hashedBytes = await window.crypto.subtle.digest("SHA-256", new TextEncoder().encode(code_verifier));
    let code_challenge_value = window.btoa(String.fromCodePoint(...new Uint8Array(hashedBytes)));
    const searchParams = new URLSearchParams();
    searchParams.append("grant_type", "authorization_code");
    searchParams.append("code_challenge_method", "S256");
    searchParams.append("code_challenge_value", code_challenge_value);
    searchParams.append("redirect_uri", redirectUri);
    searchParams.append("client_id", apiConfig.rideumClientId);
    window.location.href = `${apiConfig.loginBaseUrl}?${searchParams.toString()}`
};

const renewTokenPair = async () => {
    const refreshToken = window.localStorage.getItem("_refresh_token");
    if (!refreshToken) {
        return null;
    }
    const body = new URLSearchParams();
    body.append("grant_type", "refresh_token");
    body.append("refresh_token", refreshToken);
    const response = await fetch(apiConfig.refreshTokenUrl, {
        method: "POST",
        body: body.toString(),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });
    if (!response.ok) {
        return null;
    }
    const tokenPair = await response.json();
    window.localStorage.setItem("_access_token", tokenPair.access_token);
    if (tokenPair.expire_at) {
        window.localStorage.setItem("_token_expiry", tokenPair.expire_at);
    }
    return tokenPair;
};

export const accessToken = async () => {
    const tokenExpiryStr = window.localStorage.getItem("_token_expiry");
    if (!tokenExpiryStr) {
        throw new Error("User not log in");
    }
    var expiryDate = new Date(tokenExpiryStr);
    if (expiryDate.getTime() < new Date().getTime()) {
        //expired, renew
        await renewTokenPair();
    }
    const accessToken = window.localStorage.getItem("_access_token");
    if (!accessToken) {
        throw new Error("User not logged in");
    }
    return accessToken;
};

export const merchantProfile = async () => {
    const access_token = await accessToken();
    const response = await fetch(apiConfig.userProfileUrl, {
        headers: {
            Authorization: `Bearer ${access_token}`
        },
        method: "GET"
    });
    if (!response.ok) {
        throw new Error(await response.text());
    }
    const userInfo = await response.json();
    const merchant = await bleudineMerchantApi.getById(userInfo.merchantId,access_token);
    const locales = await getLocaleByCountryCode(merchant.countryCode.toUpperCase());
    const { hasError, data, errors } = await tenantGroupApi.getTenantGroupByRideumMerchantIdAsync(userInfo.merchantId);
    if (hasError) {
        throw new Error(errors);
    }
    userInfo.tenant_group = data;
    userInfo.tenant_group_id = data.id;
    userInfo.tenant = { locale: locales.data.locales[0] };
    userInfo.merchantCore = merchant;
    if (window.$chatwoot) {
        window.$chatwoot.reset()
    }
    return userInfo;
}

const codeExchange = async (code) => {
    const domain = window.location.origin;
    const redirectUri = `${domain}/auth-callback`;
    const codeVerifier = localStorage.getItem("_code_verifier");
    const body = new URLSearchParams();
    body.append("grant_type", "authorization_code");
    body.append("authorization_code", code);
    body.append("client_id", apiConfig.rideumClientId);
    body.append("redirect_uri", redirectUri);
    body.append("code_verifier", codeVerifier);
    const response = await fetch(apiConfig.codeExchangeUrl, {
        method: "POST",
        body: body.toString(),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });
    if (!response.ok) {
        throw new Error("Error occur during code exchange")
    }
    window.localStorage.removeItem("_code_verifier");
    const tokens = await response.json();
    if (tokens.refresh_token) {
        window.localStorage.setItem("_refresh_token", tokens.refresh_token);
    }

    if (tokens.access_token) {
        window.localStorage.setItem("_access_token", tokens.access_token);
    }
    if (tokens.expire_at) {
        window.localStorage.setItem("_token_expiry", tokens.expire_at);
    }
    window.localStorage.setItem("token_status","completed");
};

const rideumAuthProvider = {
    async login(params) {
        //this is never called.

    },
    async checkAuth() {
        if (window.location.pathname !== "/auth-callback") {
            let authenticated = await checkDbAuth();
            if(!authenticated){
                await redirectToLogin()
            }
            return;
        } else {
            //this callback is automatically handled in new version of react-admin
            await this.handleCallback()
        }

    },
    async handleCallback() {
        window.localStorage.setItem("token_status","pending");
        const url = new URL(window.location);
        let authCode = url.searchParams.get("code");
        await codeExchange(authCode);
    },
    async logout() {
        window.localStorage.removeItem("_refresh_token");
        window.localStorage.removeItem("_access_token");
    },
    async getPermissions(){
        return;
    },
    async getIdentity(){
        return;
    }
};


export default rideumAuthProvider;
