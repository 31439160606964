import {
    Button,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DownloadIcon from '@material-ui/icons/GetApp';
import moment from "moment";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import {
    Title,
    useNotify
} from "react-admin";
import * as XLSX from 'xlsx';
import { reportApi } from "../../api/reportApi";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { getFirstLastDayInCurrentMonth } from "../../utils/date";


const OccupancyReportContext = React.createContext({})

const useStyles = makeStyles((theme) => ({
    formGrid: {
        paddingTop: "0!important",
        paddingBottom: "0!important",
    },
    formInput: {
        marginTop: "0!important",
        marginBottom: "0!important",
    },
    textBold: {
        fontWeight: "bold",
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    subCardWrap: {
        minHeight: 260,
    },
}));

const OccupancyReport = () => {
    const notify = useNotify();
    const classes = useStyles();

    const [initialCheckInDate, initialCheckOutDate] =
        getFirstLastDayInCurrentMonth();
    const [selectedMonth, setSelectedMonth] = React.useState(moment().month() + "");
    const [selectedYear, setSelectedYear] = React.useState(moment().year() + "");

    const [occupencyData, setOccupencyData] = React.useState([]);
    const [checkInDate, setCheckInDate] = React.useState(initialCheckInDate);
    const [checkOutDate, setCheckOutDate] = React.useState(initialCheckOutDate);
    const [months, setMonths] = React.useState([]);
    const [years, setYears] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [generatedDate, setGeneratedDate] = React.useState(null)
    const [reportRange, setReportRange] = React.useState(null);
    const {authInfo} = useContext(AuthInfoContext);
    

    const tableRef = useRef()

    useEffect(() => {
        let arr = []
        let current_year = moment().year();
        for (let i = 0; i < 4; i++) {
            arr.push((current_year - i) + "")
        }
        setYears(arr);
        setMonths([
            { value: '0', key: "Jan" },
            { value: '1', key: "Feb" },
            { value: '2', key: "Mar" },
            { value: '3', key: "Apr" },
            { value: '4', key: "May" },
            { value: '5', key: "Jun" },
            { value: '6', key: "Jul" },
            { value: '7', key: "Aug" },
            { value: '8', key: "Sep" },
            { value: '9', key: "Oct" },
            { value: '10', key: "Nov" },
            { value: '11', key: "Dec" },
        ]);
        searchReport()

    }, [])

    useEffect(() => {
        if (selectedYear && selectedMonth) {
            let startDate = moment([selectedYear, selectedMonth])
            let endDate = moment(startDate).endOf('month')
            setCheckInDate(startDate.format("YYYY-MM-DD"))
            setCheckOutDate(endDate.format("YYYY-MM-DD"))
        }
    }, [selectedYear, selectedMonth])


    const searchReport = () => {
        setLoading(true)
        setGeneratedDate(null)
        reportApi.generateOccupancyReport(moment(checkInDate), moment(checkOutDate), authInfo.tenant.id).then((data) => {
            setOccupencyData(data);
            setGeneratedDate(moment().format("YYYY-MM-DD HH:mm:ss.SSS"))
            setReportRange({year: moment(checkInDate).year(), month: moment(checkInDate).month()+""})
        }).finally(() => setLoading(false))


    };
    const handleExport = useCallback(() => {
        if (tableRef.current && reportRange) {
            let workbook = XLSX.utils.table_to_book(tableRef.current, {sheet:"Occupancy_Report"})
            XLSX.writeFile(workbook, `${reportRange.year}-${months.find(m=> m.value==reportRange.month).key} Occupancy report.xlsx`, { compression: true })
        }
    }, [tableRef, reportRange, months]);

    return (
        <OccupancyReportContext.Provider value={{ occupencyData, loading, generatedDate, selectedYear, months, selectedMonth, tableRef }}>
            <div>
                <Title title={"Occupancy"} />
                <Grid
                    container
                    spacing={2}
                    style={{
                        marginTop: 12,
                    }}
                >
                    <Grid item xs={12} sm={2} className={classes.formGrid}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Year</InputLabel>
                            <Select value={selectedYear ?? ""} autoWidth label="Year" onChange={(evt) => setSelectedYear(evt.target.value)}>
                                <MenuItem disabled value="">Year</MenuItem>
                                {years.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2} className={classes.formGrid}>

                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Month</InputLabel>
                            <Select value={selectedMonth ?? ""} autoWidth label="Month" onChange={(evt) => setSelectedMonth(evt.target.value)}>
                                <MenuItem disabled value="">Month</MenuItem>
                                {months.map(month => <MenuItem value={month.value + ""} key={month.key}>{month.key}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.formGrid}>
                        <TextField
                            type="date"
                            variant="filled"
                            className={classes.formInput}
                            value={checkInDate}
                            disabled
                            fullWidth
                            label="Start Date"
                        />
                    </Grid>

                    <Grid item xs={12} sm={3} className={classes.formGrid}>
                        <TextField
                            disabled
                            type="date"
                            variant="filled"
                            className={classes.formInput}
                            value={checkOutDate}
                            fullWidth
                            label="End Date"
                        />
                    </Grid>
                    <Grid item xs={12} sm={1} className={classes.formGrid}>
                        <Button
                            style={{
                                marginTop: 12,
                                marginRight: 5,
                            }}
                            onClick={searchReport}
                            variant="contained"
                            color="primary"
                        >
                            Search
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={1} className={classes.formGrid} >
                        <Button
                            style={{
                                marginTop: 12,
                                marginRight: 5,
                            }}
                            variant="text"
                            color="primary"
                            endIcon={<DownloadIcon />}
                            onClick={handleExport}
                        >
                            Export
                        </Button>

                    </Grid>
                    <Grid item xs={12}>
                        <OccupancyTable />
                    </Grid>
                </Grid>
            </div>
        </OccupancyReportContext.Provider>
    );
};


function OccupancyTable() {
    const { occupencyData, loading, generatedDate, selectedYear, months, selectedMonth, tableRef } = useContext(OccupancyReportContext)
    return loading ? <Typography>Loading</Typography> : <TableContainer component={Paper}>
        {generatedDate ? <Typography style={{ fontWeight: 'bold' }}>Occupancy report for {months.find(m => m.value == selectedMonth).key} {selectedYear} generated at {generatedDate}</Typography> : undefined}
        <Table ref={tableRef}>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Room type</TableCell>
                    <TableCell>Available</TableCell>
                    <TableCell>Staying</TableCell>
                    <TableCell>Occupancy rate</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {occupencyData.length == 0 ? <TableRow>
                    <TableCell>No record found</TableCell>
                </TableRow> : occupencyData.map(record => <><TableRow key={record.date}>
                    <TableCell rowSpan={Object.keys(record).filter(k => k != 'date').length + 1}>
                        {record.date}
                    </TableCell>
                    {Object.keys(record).filter(k => k != 'date').filter((k, i) => i == 0).map(key => <>
                        <TableCell>{key}</TableCell>
                        <TableCell>{record[key].available}</TableCell>
                        <TableCell>{record[key].occupied}</TableCell>
                        <TableCell>{record[key].available == 0 ? 0.00 : (record[key].occupied / record[key].available * 100).toFixed(2)}</TableCell>
                    </>)}
                </TableRow>
                    {Object.keys(record).filter(k => k != 'date').filter((k, i) => i > 0).map(key => <TableRow key={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell>{record[key].available}</TableCell>
                        <TableCell>{record[key].occupied}</TableCell>
                        <TableCell>{record[key].available == 0 ? 0.00 : (record[key].occupied / record[key].available * 100).toFixed(2)}</TableCell>
                    </TableRow>)}
                    <TableRow>
                        <TableCell><Typography style={{ fontWeight: 'bold' }}>Total</Typography></TableCell>
                        <TableCell><Typography style={{ fontWeight: 'bold' }}>{Object.keys(record).filter(k => k != 'date').reduce((acc, curr) => acc + record[curr].available, 0)}</Typography></TableCell>
                        <TableCell><Typography style={{ fontWeight: 'bold' }}>{Object.keys(record).filter(k => k != 'date').reduce((acc, curr) => acc + record[curr].occupied, 0)}</Typography></TableCell>
                        <TableCell><Typography style={{ fontWeight: 'bold' }}>{(Object.keys(record).filter(k => k != 'date').reduce((acc, curr) => acc + record[curr].occupied, 0) / Object.keys(record).filter(k => k != 'date').reduce((acc, curr) => acc + record[curr].available, 0) * 100).toFixed(2)}</Typography></TableCell>
                    </TableRow>
                </>)}

            </TableBody>
        </Table>
    </TableContainer>
}

function reportList(occupencyData) {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Room type</TableCell>
                        <TableCell>Room qty</TableCell>
                        <TableCell>Occupied rooms qty</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {occupencyData?.map((row) => (
                        <TableRow>
                            <TableCell>{row.a_date}</TableCell>
                            <TableCell>{row.room_type}</TableCell>
                            <TableCell>{row.room_qty}</TableCell>
                            <TableCell>{row.occupied_rooms_qty ?? 0}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default OccupancyReport;
