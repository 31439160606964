import { executeAsync, output } from "./hasura";

export const tenantGroupApi = {
  getTenantGroupIdAsync,
  getRideumMerchantAsync,
  updateRideumMerchantAsync,
  removeRideumMerchantAsync,
    getTenantGroupByRideumMerchantIdAsync
}

async function getTenantGroupIdAsync(tenant_id) {
  var gql = `
    query GetTenantGroupId($tenant_id: uuid!) {
      tenants_by_pk(id: $tenant_id) {
        tenant_group_id
      }
    }
  `;

  const { data, errors } = await executeAsync({ tenant_id }, gql);
  const value = {
    tenant_group_id: data?.tenants_by_pk?.tenant_group_id
  }
  return output(value, errors);
}

async function getTenantGroupByRideumMerchantIdAsync(rideumMerchantId){
    var gql = `query GetTenantGroupByRideumMerchantId($merchantId: uuid!){
        tenant_groups(where:{mapped_id:{_eq:$merchantId}}){
            address_1
            address_2
            city
            country
            created_at
            e_country{
                comment
                id
            }
            email
            id
            locale {
                country
                currency_code
                decimal_seperator
                precision
                thousand_seperator
            }
            name
            phone_no
            region
            status
            updated_at
            zip_code
        }
    }`;

    const params =  {merchantId:rideumMerchantId};
    const {data,errors} = await executeAsync(params, gql);
    const value =  data?.tenant_groups[0];
    return output(value,errors);
}

async function getRideumMerchantAsync (tenant_group_id) {
  var gql = `
    query GetMerchant($tenant_group_id: uuid!) {
			tenant_groups_by_pk(id: $tenant_group_id) {
				rideum_merchant_id
        rideum_merchant_name
			}
    }
  `;

  const { data, errors } = await executeAsync({ tenant_group_id }, gql);
  const value = {
    id: data?.tenant_groups_by_pk?.rideum_merchant_id,
    name: data?.tenant_groups_by_pk?.rideum_merchant_name,
  };
  return output(value, errors);
}


async function updateRideumMerchantAsync(tenant_group_id, rideum_merchant_id, rideum_merchant_name) {
  var gql = `
    mutation UpdateRideumMerchantId($tenant_group_id: uuid!, $rideum_merchant_id: uuid!, $rideum_merchant_name: String!) {
      update_tenant_groups_by_pk(pk_columns: {id: $tenant_group_id}, _set: {rideum_merchant_id: $rideum_merchant_id, rideum_merchant_name: $rideum_merchant_name}) {
        id
      }
    }
  `;

  const { data, errors } = await executeAsync({ tenant_group_id,
                                                rideum_merchant_id,
                                                rideum_merchant_name
                                              }, gql);
  const value = {
    id: data?.update_tenant_groups_by_pk?.id
  };
  return output(value, errors);
}

async function removeRideumMerchantAsync(tenant_group_id) {
  var gql = `
    mutation UpdateRideumMerchantId($tenant_group_id: uuid!) {
      update_tenant_groups_by_pk(pk_columns: {id: $tenant_group_id}, _set: {rideum_merchant_id: null, rideum_merchant_name: null}) {
        id
      }
    }
  `;

  const { data, errors } = await executeAsync({ tenant_group_id }, gql);
  const value = {
    id: data?.update_tenant_groups_by_pk?.id
  };
  return output(value, errors);
}
