import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import jsonExport from "jsonexport/dist";
import moment from "moment";
import * as React from "react";
import {
  Datagrid,
  DateInput,
  downloadCSV,
  EditButton,
  FunctionField,
  List,
  TextField,
  TextInput
} from "react-admin";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { useContext } from "react";


const filters = [
  <TextInput
    source="title"
    label="Search"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <DateInput
    style={{ width: 250 }}
    source="start_date"
    label="Start Date"
    alwaysOn
  />,
  <DateInput
    style={{ width: 250 }}
    source="end_date"
    label="End Date"
    alwaysOn
  />,
];

const exporter = (records) => {
  const forExport = records.map((record) => {
    return {
      room: record.room.name,
      title: record.title,
      start_date: record.start_date,
      end_date: record.end_date,
      created_date: moment(record.created_at).format("DD MMM YYYY hh:mm:ss A"),
    };
  });
  jsonExport(
    forExport,
    {
      headers: ["room", "title", "start_date", "end_date", "created_date"],
    },
    (err, csv) => {
      downloadCSV(csv, "maintenances");
    }
  );
};

const MaintenanceList = (props) => {
  const {authInfo} = useContext(AuthInfoContext);
  

  return (
    <List
      sort={{ field: "created_at", order: "Desc" }}
      filters={filters}
      filterDefaultValues={{ tenant_id: authInfo.tenant.id }}
      bulkActionButtons={false}
      exporter={exporter}
      {...props}
    >
      <Datagrid>
        <TextField source="title" label="Title" />
        <TextField source="room.name" label="Room" />
        <TextField source="start_date" label="Start Date" />
        <TextField source="end_date" label="End Date" />
        <FunctionField
          sortBy="created_at"
          label="Created Date"
          render={(record) =>
            moment(record.created_at).format("DD MMM YYYY hh:mm:ss A")
          }
        />
        <EditButton style={{ float: "right" }} basePath="/maintenances" />
      </Datagrid>
    </List>
  );
}

export default MaintenanceList;
