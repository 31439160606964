import React from "react";
import { Route } from "react-router-dom";
import Configuration from "./resources/configuration/Configuration";
import ChangePassword from "./resources/password/ChangePassword";
import FrontDesk from "./resources/dashboard/FrontDesk";
import Housekeeping from "./resources/housekeeping/Housekeeping";
import ConnectRideum from "./resources/connect/ConnectRideum";

//Reports
import AccountingReport from "./resources/reports/AccountingReport.js";
import BookingBalanceReport from "./resources/reports/BookingBalanceReport";
import OccupancyReport from "./resources/reports/OccupancyReport.js";
import SalesReport from "./resources/reports/SalesReport.js";
import DynamicRoomRateList from "./resources/dynamicRates/DynamicRoomRateList.jsx";
import BusinessInsight from "./resources/BusinessInsight.jsx";
import CheckInCheckOutReport from "./resources/reports/CheckInCheckout.jsx";
import EndofDayReport from "./resources/reports/EndofDayReport.jsx";
import AccountBalanceReport from "./resources/reports/AccountBalanceReport.jsx";
import FinancialReport from "./resources/reports/FinancialReport.jsx";
import OAuthCallback from "./auth/OAuthCallback.jsx";

//ChannelManage
//import ChannelManagerAccount from "./resources/channelmanager/ChannelManagerAccounts.js";

export default [
    <Route exact path="/configuration" component={Configuration} />,
    <Route exact path="/password/change" component={ChangePassword} />,
    <Route exact path="/frontDesk" component={FrontDesk} />,
    <Route exact path="/housekeeping" component={Housekeeping} />,
    <Route exact path="/connect-rideum" component={ConnectRideum} />,
    //Reports
    <Route exact path="/accounting-report" component={AccountingReport} />,
    <Route
        exact
        path="/booking-balance-report"
        component={BookingBalanceReport}
    />,
    <Route exact path="/occupancy-report" component={OccupancyReport} />,
    <Route exact path="/sales-report" component={SalesReport} />,
    <Route exact path="/dynamic_rates" component={DynamicRoomRateList} />,
    <Route exact path="/dashboard" component={BusinessInsight} />,
    <Route exact path="/checkin-checkout-reports" component={CheckInCheckOutReport} />,
    <Route exact path="/end-of-day-reports" component={EndofDayReport} />,
    <Route exact path="/account-balance" component={AccountBalanceReport} />,
   <Route exact path="/financial-report" component={FinancialReport}/>,
    <Route exact path="/auth-callback" component={OAuthCallback} noLayout/>
    //Channel Manager
    /*   <Route
      exact
      path="/channel-manager-accounts"
      component={ChannelManagerAccount}
    />, */
];
