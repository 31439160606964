import { Button, InputAdornment } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import jsonExport from 'jsonexport/dist';
import moment from "moment";
import * as React from "react";
import { useCallback } from "react";
import {
  Datagrid,
  ExportButton,
  FunctionField,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  downloadCSV,
  sanitizeListRestProps,
  useListContext
} from "react-admin";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { refundStatus } from "../../models/refundStatus";
import { formatter } from "../../utils/formatter";
import { useContext } from "react";


const filters = [
  <TextInput
    type="number"
    source="refund_no"
    label="Refund No"
    alwaysOn
    resettable
    InputProps={{
      startAdornment: <InputAdornment>R</InputAdornment>,
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    style={{ width: 250 }}
    label="Type"
    source="type"
    reference="e_refund_types"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
  <ReferenceInput
    style={{ width: 250 }}
    label="Status"
    source="status"
    reference="e_refund_status"
    alwaysOn
    resettable
    filter={{ comment: ["Confirmed", "Cancelled"] }}
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
];

const ListActions = (props) => {
  const { className, filters, maxResults, ...rest } = props;
  const { total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ExportButton disabled={total === 0} maxResults={maxResults} />
    </TopToolbar>
  );
};



const RefundList = (props) => {
  const {authInfo} = useContext(AuthInfoContext);
  const exporter = useCallback(records => {

    const forExport = records.map(record => {
      return {
        refund_no: formatter.refundNo(record.refund_no),
        reference: record.order != null
          ? "Order - " + formatter.orderNo(record.order.order_no)
          : record.deposit != null ?
            "Deposit - " + formatter.depositNo(record.deposit.deposit_no)
            : null,
        type: record.e_refund_type.comment,
        amount: formatter.formatMoney(
          record.amount,
          authInfo.tenant.locale.currency_code,
          authInfo.tenant.locale.precision
        ),
        created_date: moment(record.created_at).format("DD MMM YYYY hh:mm:ss A"),
        status: record.e_refund_status.comment,
      };
    });
    jsonExport(forExport, {
      headers: ['refund_no', 'reference', 'type', 'amount', 'created_date', 'status']
    }, (err, csv) => {
      downloadCSV(csv, 'refunds');
    });
  }, [authInfo]);

  return (
    <List
      filters={filters}
      filterDefaultValues={{ tenant_id: authInfo.tenant.id }}
      actions={<ListActions />}
      sort={{ field: "created_at", order: "DESC" }}
      bulkActionButtons={false}
      exporter={exporter}
      {...props}
    >
      <Datagrid>
        <FunctionField
          sortBy="refund_no"
          label="Refund No"
          render={(record) => formatter.refundNo(record.refund_no)}
        />
        <FunctionField
          label="Reference"
          render={(record) =>
            record.order != null
              ? "Order - " + formatter.orderNo(record.order.order_no)
              : record.deposit != null ?
                "Deposit - " + formatter.depositNo(record.deposit.deposit_no)
                : null
          }
        />
        <TextField sortBy="type" source="e_refund_type.comment" label="Type" />
        <FunctionField
          sortBy="amount"
          label="Amount"
          render={(record) =>
            formatter.formatMoney(
              record.amount,
              authInfo.tenant.locale.currency_code,
              authInfo.tenant.locale.precision
            )
          }
        />
        <FunctionField
          sortBy="created_at"
          label="Created Date"
          render={(record) =>
            moment(record.created_at).format("DD MMM YYYY hh:mm:ss A")
          }
        />
        <FunctionField
          sortBy="status"
          label="Status"
          render={(record) => (
            <Chip
              label={record.e_refund_status.comment}
              style={{
                color: "white",
                background: refundStatus.getColor(record.e_refund_status.id),
              }}
            />
          )}
        />
        <FunctionField
          render={(record) => (
            <Button
              style={{ border: "none", float: "right" }}
              startIcon={<EditIcon />}
              variant="outlined"
              color="primary"
              href={`/bookings/${record.booking_id}`}
              target="_blank"
            >
              View Booking
            </Button>
          )}
        />
      </Datagrid>
    </List>
  );
}

export default RefundList;
