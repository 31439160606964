import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  TextInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
  downloadCSV
} from 'react-admin';
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { userRole } from "../../models/userRole";
import { userStatus } from "../../models/userStatus";
import Chip from '@material-ui/core/Chip';
import jsonExport from 'jsonexport/dist';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AuthInfoContext } from '../../hooks/useAuthInfo';

const userFilters = [
  <TextInput
    source="email,first_name,last_name"
    label="Search"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    alwaysOn
    resettable
    style={{ width: 250 }}
    label="Role"
    source="role"
    reference="e_user_roles"
    filter={{ comment: ["Frontdesk", "Housekeeper"] }}
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
  <ReferenceInput
    style={{ width: 250 }}
    label="Status"
    source="status"
    reference="e_user_status"
    alwaysOn
    resettable
    filter={{ comment: ["Active", "Inactive"] }}
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>
];


const managerFilters = [
  <TextInput
    source="email,first_name,last_name"
    label="Search"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  // <ReferenceInput
  //   alwaysOn
  //   style={{ width: 250 }}
  //   label="Role"
  //   source="role"
  //   reference="e_user_roles"
  //   filter={{ comment: ["Manager"] }}
  // >
  //   <SelectInput optionText="comment" />
  // </ReferenceInput>,
  <ReferenceInput
    style={{ width: 250 }}
    label="Status"
    source="status"
    reference="e_user_status"
    alwaysOn
    resettable
    filter={{ comment: ["Active", "Inactive"] }}
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>
];


const exporter = records => {
  const forExport = records.map(record => {
    return {
      first_name: record.first_name,
      last_name: record.last_name,
      email: record.email,
      role: record.e_user_roles.comment,
      status: record.e_user_status.comment,
    };
  });
  jsonExport(forExport, {
    headers: ['first_name', 'last_name', 'email', 'role', 'status']
  }, (err, csv) => {
    downloadCSV(csv, 'users');
  });
};

const UserList = props => {
  const {authInfo} = useContext(AuthInfoContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const roleQueryParam = queryParams.get('role');
  const filterDefaultValues = roleQueryParam ? {
    tenant_group_id: authInfo.tenant_group_id,
    is_deleted: false,
    role: roleQueryParam,
    
  } : {
    tenant_id: authInfo.tenant.id,
    is_deleted: false,
  };

  return (
    <List sort={{ field: 'created_at', order: 'Desc' }} filters={roleQueryParam ? managerFilters : userFilters} filterDefaultValues={filterDefaultValues} bulkActionButtons={false} exporter={exporter} {...props}>
      <Datagrid>
        <TextField source="first_name" />
        <TextField source="last_name" />
        <EmailField source="email" />
        <FunctionField 
          sortBy="role" 
          label="Role" 
          render={record => 
            <Chip 
              label={record.e_user_roles?.comment} 
              style={{ color: 'white', background: userRole.getColor(record.e_user_roles?.id) }} 
            />
          } 
        />
        <FunctionField 
          sortBy="status" 
          label="Status" 
          render={record => 
            <Chip 
              label={record.e_user_status?.comment} 
              style={{ color: 'white', background: userStatus.getColor(record.e_user_status?.id) }} 
            />
          } 
        />
        <EditButton style={{ float: "right" }} basePath={`/users`}/>
      </Datagrid>
    </List>
  );
}

export default UserList;