import React, { useContext } from "react";
import { DataProviderContext, SaveButton, useNotify, useRedirect } from "react-admin";
import { useFormState } from "react-final-form";
import { AuthInfoContext } from "../../hooks/useAuthInfo";

const CustomSaveButton = (formProps) => {
  const {authInfo} = useContext(AuthInfoContext);
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useContext(DataProviderContext);
  let { values } = useFormState();
  const [saving, setSaving] = React.useState(false);

  //massage data
  var request = {
    ...values,
    tenant_id: authInfo.tenant.id,
    status: "Complete",
  };

  const handleCreateClick = () => {
    setSaving(true);

    dataProvider
      .create(formProps.resource, { data: { ...request } })
      .then(({ data }) => {
        redirect(`/cm_2fa_sessions`);
        notify("Channel manager client created!");
      })
      .catch((error) => {
        setSaving(false);

        let message = "Something went wrong.";

        if (
          error?.message.includes("duplicate") &&
          error?.message.includes("cm_2fa_sessions_cm_account_id_fkey")
        ) {
          message =
            "Fail to update client, already have account with same client";
        }

        notify(message, "error");
      });
  };

  const handleUpdateClick = () => {
    setSaving(true);
    dataProvider
      .update(formProps.resource, { id: request.id, data: { ...request } })
      .then(({ data }) => {
        redirect(`/cm_2fa_sessions`);
        notify("Client updated");
      })
      .catch((error) => {
        setSaving(false);
        let message = "Something went wrong.";

        if (
          error?.message.includes("duplicate") &&
          error?.message.includes("cm_2fa_sessions_cm_account_id_fkey")
        ) {
          message =
            "Fail to update client, already have account with same client";
        }

        notify(message, "error");
      });
  };

  return (
    <SaveButton
      {...formProps}
      saving={saving}
      disabled={saving}
      onSave={values.id != undefined ? handleUpdateClick : handleCreateClick}
    />
  );
};

export default CustomSaveButton;
