import React, { useCallback, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceInput,
  SelectInput,
  TextInput,
  FunctionField,
  downloadCSV,
  Confirm,
  useRefresh,
  useNotify
} from 'react-admin';
import { InputAdornment, Switch } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { roomRateStatus } from "../../models/roomRateStatus";
import Chip from '@material-ui/core/Chip';
import jsonExport from 'jsonexport/dist';
import { setIbeRate, setOtaRate, unsetIbeRate, unsetOtaRate } from "../../services/roomRateService";
import { auth } from "firebase";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { useContext } from "react";


const exporter = records => {
  const forExport = records.map(record => {
    return {
      name: record.name,
      cancellation_policy: record.cancellation_policy.name,
      status: record.e_room_rate_status.comment,
    };
  });
  jsonExport(forExport, {
    headers: ['name', 'cancellation_policy', 'status']
  }, (err, csv) => {
    downloadCSV(csv, 'room_rates');
  });
};

const RoomRateList = props => {
  const {authInfo} = useContext(AuthInfoContext);
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const updateIbeRate = useCallback(async (record) => {
    try {
      setLoading(true);
      if (record.is_ibe) {
        await unsetIbeRate(record.id);
      } else {
        await setIbeRate({ tenantId: authInfo.tenant.id, roomRateId: record.id })
      }
      refresh();
    } catch (e) {
      notify(e.message, "error");
    } finally {
      setLoading(false);
    }

  }, [authInfo.tenant.id, refresh]);
  const updateOtaRate = useCallback(async (record) => {
    try {
      setLoading(true);
      if (record.is_ota) {
        await unsetOtaRate(record.id);
      } else {
        await setOtaRate({ tenantId: authInfo.tenant.id, roomRateId: record.id })
      }
      refresh();
    } catch (e) {
      notify(e.message, "error");
    } finally {
      setLoading(false);
    }

  }, [authInfo.tenant.id, refresh]);
  const filters = [
    <TextInput
      source="name"
      label="Search"
      alwaysOn
      resettable
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
    />,
    <ReferenceInput
      style={{ width: 250 }}
      label="Cancellation Policy"
      source="cancellation_policy_id"
      reference="cancellation_policies"
      alwaysOn
      resettable
      filterToQuery={() => ({ tenant_id: authInfo.tenant.id })}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>,
    <ReferenceInput
      style={{ width: 250 }}
      label="Status"
      source="status"
      reference="e_room_rate_status"
      alwaysOn
      resettable
    >
      <SelectInput optionText="comment" />
    </ReferenceInput>
  ];

  return (
    <List sort={{ field: 'created_at', order: 'Desc' }} filters={filters} filterDefaultValues={{ tenant_id: authInfo.tenant.id }} bulkActionButtons={false} exporter={exporter} {...props}>
      <Datagrid>
        <TextField source="name" label="ra.column.name" />
        <TextField source="cancellation_policy.name" label="Cancellation Policy" />
        <FunctionField label="IBE Rate" render={record => <Switch checked={record.is_ibe} disabled={loading} onChange={()=> updateIbeRate(record)}/>} />
        <FunctionField label="OTA Rate" render={record => <Switch checked={record.is_ota} disabled={loading} onChange={()=> updateOtaRate(record)} />} />

        <FunctionField sortBy="status" label="Status" render={record => <Chip label={record.e_room_rate_status?.comment} style={{ color: 'white', background: roomRateStatus.getColor(record.e_room_rate_status?.id) }} />} />
        <EditButton style={{ float: "right" }} basePath="/room_rates" />
      </Datagrid>
    </List>
  )
}

export default RoomRateList;
