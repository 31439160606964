import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/GetApp'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Title } from 'react-admin'
import * as XLSX from 'xlsx'
import { reportApi } from '../../api/reportApi'
import { AuthInfoContext } from '../../hooks/useAuthInfo'
import { useContext } from 'react'

export default function CheckInCheckOutReport() {
    const {authInfo} = useContext(AuthInfoContext);
    
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [reportDate, setReportDate] = useState(undefined)
    const [report, setReport] = useState([])

    useEffect(() => {
        generateReport()
    }, []);

    const exporter = () => {

        const first_data = [
            [`This is check in check out report generated on ${moment(selectedDate).format("YYYY-MM-DD")}`],
        ]
        const check_in_check_out_data = report.map(record => {
            return {
                room: record.room,
                check_in: record.check_in,
                check_out: record.check_out
            }
        })

        const check_in_check_out_sheet = XLSX.utils.aoa_to_sheet(first_data)

        /* generate workbook and add the worksheet */
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, check_in_check_out_sheet, 'Check_In_Check_Out');
        XLSX.utils.sheet_add_json(wb.Sheets.Check_In_Check_Out, check_in_check_out_data, { origin: "A3" })

        /* save to file */
        XLSX.writeFile(wb, `Check_In_Check_Out_${moment(selectedDate).format("YYYY-MM-DD")}.xlsx`);
    };

    const generateReport = useMemo(() => {
        return () => {
            reportApi.checkInCheckoutReportByDate(authInfo.tenant_id, new Date(selectedDate)).then(({ data }) => {
                const { accommodations } = data;
                let rooms = []
                accommodations.map(acc => acc.room.name).forEach(roomName => {
                    if (!rooms.includes(roomName)) {
                        rooms.push(roomName)
                    }
                })
                const guestName = (acc) => {
                    if (!acc) {
                        return ""
                    }
                    return acc.booking.customer_type == 'Individual' ? `${acc.booking.guest.first_name} ${acc.booking.guest.last_name}` : acc.booking.company.name
                }
                const result = rooms.map(room => ({
                    room, check_in: guestName(accommodations.find(acc => acc.room.name == room && (acc.check_in_date == selectedDate && acc.status == "Checked_In"))),
                    check_out: guestName(accommodations.find(acc => acc.room.name == room && (acc.check_out_date >= selectedDate && acc.status == "Checked_Out")))
                }))
                setReport(result)
                setReportDate(selectedDate)
            })

        }
    }, [selectedDate])

    return <div style={{ padding: '1rem' }}>
        <Title title={'Check in/Check out report'}></Title>
        <Grid container direction="column">
            <Grid item>
                <Grid container direction="row" alignItems='center' spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            style={{
                                marginTop: 12,
                            }}
                            type="date"
                            variant="filled"
                            onChange={(evt) => setSelectedDate(moment(evt.target.valueAsDate).format('YYYY-MM-DD'))}
                            value={selectedDate}
                            fullWidth
                            label="Start Date"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            style={{
                                marginTop: 12,
                                marginRight: 5,
                            }}
                            onClick={generateReport}
                            variant="contained"
                            color="primary"
                        >
                            Search
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ marginLeft: 'auto', textAlignLast: 'end' }}>
                        <Button
                            style={{
                                marginTop: 12,
                                marginRight: 5,
                            }}
                            onClick={exporter}
                            variant="text"
                            color="primary"
                            endIcon={<DownloadIcon />}
                        >
                            Export
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid item>
                {/* <Typography>Check in/Check out list for {selectedDate}</Typography> */}
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Room</TableCell>
                                <TableCell>Check In</TableCell>
                                <TableCell>Check Out</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{report.length == 0 ? <TableRow><TableCell colSpan={3}><Typography align='center'>No record found</Typography></TableCell></TableRow> :
                            report.map(record => <TableRow key={record.room}>
                                <TableCell>{record.room}</TableCell>
                                <TableCell>{record.check_in}</TableCell>
                                <TableCell>{record.check_out}</TableCell>
                            </TableRow>)
                        }</TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </div >
}
