import { Box, Card, CardContent, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  AutocompleteInput,
  email,
  FormWithRedirect,
  maxLength,
  minLength,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useTranslate
} from "react-admin";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { IntlPhoneInput } from "../components/IntlPhoneInput";
import { useContext } from "react";


const validateName = [required(), minLength(2), maxLength(25)];
const validateEmail = [required(), email()];

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const CompanyForm = (props) => {
  const {authInfo} = useContext(AuthInfoContext);
  const classes = useStyles();
  const translate = useTranslate();
  const { source, ...rest } = props;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Company Info
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.formGrid}
                  style={{ display: "none" }}
                >
                  <TextInput
                    className={classes.formInput}
                    source="tenant_id"
                    label="Tenant ID"
                    fullWidth
                    defaultValue={authInfo.tenant.id}
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="companies"
                    source="name"
                    fullWidth
                    validate={validateName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="companies"
                    source="email"
                    type="email"
                    label="ra.column.email"
                    fullWidth
                    validate={validateEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <IntlPhoneInput
                    className={classes.formInput}
                    resource="companies"
                    source="phone_no"
                    label={translate("ra.column.phone_no")}
                    {...rest}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="companies"
                    source="address_1"
                    label="ra.column.address_1"
                    multiline
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="companies"
                    source="address_2"
                    label="ra.column.address_2"
                    multiline
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="companies"
                    source="zip_code"
                    label="ra.column.zip_code"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="companies"
                    source="region"
                    label="ra.column.region"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="companies"
                    source="city"
                    label="ra.column.city"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="country"
                    reference="e_countries"
                    sort={{ field: "comment", order: "ASC" }}
                    filterToQuery={(searchText) => ({
                      comment: searchText,
                    })}
                    label="ra.column.country"
                    fullWidth
                  >
                    <AutocompleteInput
                      optionText="comment"
                      options={{ InputProps: { autoComplete: "no" } }}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ReferenceInput
                    className={classes.formInput}
                    fullWidth
                    label="Status"
                    source="status"
                    reference="e_company_status"
                    validate={[required()]}
                  >
                    <SelectInput optionText="comment" />
                  </ReferenceInput>
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton {...formProps} />
                {/* {formProps.record.id != undefined ?
                  <DeleteButton record={formProps.record} />
                  : null} */}
              </Box>
            </Toolbar>
            {/* <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmitWithRedirect}
              saving={formProps.saving}
              resource="companies"
            /> */}
          </form>
        </Card>
      )}
    />
  );
};

export default CompanyForm;
