import { Button, TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DownloadIcon from '@material-ui/icons/GetApp';
import React, { useEffect } from "react";
import { Title, useNotify, useTranslate } from "react-admin";
import * as XLSX from 'xlsx';
import { reportApi } from "../../api/reportApi";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { getFirstLastDayInCurrentMonth } from "../../utils/date";
import { formatter } from "../../utils/formatter";
import { useContext } from "react";

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subCardWrap: {
    minHeight: 260,
  },
}));

const SalesReport = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();

  const [initialCheckInDate, initialCheckOutDate] =
    getFirstLastDayInCurrentMonth();

  const [salesReport, setSalesReport] = React.useState([]);
  const [checkInDate, setCheckInDate] = React.useState(initialCheckInDate);
  const [checkOutDate, setCheckOutDate] = React.useState(initialCheckOutDate);

  const {authInfo} = useContext(AuthInfoContext);
  
  const currency = authInfo.tenant.locale.currency_code;
  useEffect(() => {
    searchReport();
  }, []);

  const searchReport = () => {
    reportApi
      .getSalesReport(authInfo.tenant.id, checkOutDate, checkInDate)
      .then(({ data }) => {
        setSalesReport(data.get_sales_report)

      })
      .catch((error) => notify(error));
  };

  const exporter = () => {
    const first_data = [
      [`This is sales report generated from ${checkInDate} to ${checkOutDate}`],
    ]

    const sales_data = salesReport?.map((record) => {
      return {
        booking_source: record.booking_source,
        no_of_booking: record.no_of_booking,
        total_income: formatter.formatMoney(
          record.total_income,
          authInfo.tenant.locale.currency_code,
          authInfo.tenant.locale.precision
        ),
        paid: formatter.formatMoney(
          record.paid,
          authInfo.tenant.locale.currency_code,
          authInfo.tenant.locale.precision
        ),
        debt: formatter.formatMoney(
          record.total_income - record.paid,
          authInfo.tenant.locale.currency_code,
          authInfo.tenant.locale.precision
        )
      }
    })

    const sales_sheet = XLSX.utils.aoa_to_sheet(first_data)

     /* generate workbook and add the worksheet */
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, sales_sheet, 'Sales');
        XLSX.utils.sheet_add_json(wb.Sheets.Sales, sales_data, { origin: "A3" })

    /* save to file */
    XLSX.writeFile(wb, `Sales_Report_from_${checkInDate}_to${checkOutDate}.xlsx`);
  };
  return (
    <div>
      <Title title={translate("ra.label.sales")} />
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 12,
        }}
      >
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <TextField
            style={{
              marginTop: 12,
            }}
            type="date"
            variant="filled"
            className={classes.formInput}
            value={checkInDate}
            onChange={(e) => setCheckInDate(e.target.value)}
            fullWidth
            label="Start Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <TextField
            type="date"
            variant="filled"
            className={classes.formInput}
            value={checkOutDate}
            onChange={(e) => setCheckOutDate(e.target.value)}
            fullWidth
            label="End Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <Button
            style={{
              marginTop: 12,
              marginRight: 5,
            }}
            onClick={searchReport}
            variant="contained"
            color="primary"
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} style={{ marginLeft: 'auto', textAlignLast: 'end' }}>
          <Button
            style={{
              marginTop: 12,
              marginRight: 5,
            }}
            onClick={exporter}
            variant="text"
            color="primary"
            endIcon={<DownloadIcon />}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12}>
          {reportList(salesReport)}
        </Grid>
      </Grid>
    </div>
  );

  function reportList(salesData) {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Booking Source</TableCell>
              <TableCell>No of Booking</TableCell>
              <TableCell>Total Income</TableCell>
              <TableCell>Paid</TableCell>
              <TableCell>Debt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesData ?
              salesData?.map((row) => (
                <TableRow>
                  <TableCell>{row.booking_source}</TableCell>
                  <TableCell>{row.no_of_booking}</TableCell>
                  <TableCell> {formatter.formatMoney(
                    row.total_income,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                  )}</TableCell>
                  <TableCell> {formatter.formatMoney(
                    row.paid,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                  )}</TableCell>
                  <TableCell> {formatter.formatMoney(
                    row.total_income - row.paid,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                  )}</TableCell>
                </TableRow>
              )) :
              <TableRow>
                <TableCell colSpan={3}><Typography align='center'>No record found</Typography>
                </TableCell>
              </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default SalesReport;
/* 
const reportPage = (translatableTitle, rowValuesExtractor, columnTitles) => {
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();

  const [initialCheckInDate, initialCheckOutDate] =
    getFirstLastDayInCurrentMonth();

  const [reportData, setReportData] = React.useState([]);
  const [checkInDate, setCheckInDate] = React.useState(initialCheckInDate);
  const [checkOutDate, setCheckOutDate] = React.useState(initialCheckOutDate);

  const authInfo = JSON.parse(localStorage.getItem("auth"));

  useEffect(reloadReportPageData, []);

  const reloadReportPageData = () => {
    reportApi
      .getSalesReport(authInfo.tenant.id, checkOutDate, checkInDate)
      .then(({ data }) => setSalesReport(data.get_sales_report))
      .catch((error) => notify(error));
  };

  return _reportList(reportData, rowValuesExtractor, columnTitles);
};

function _reportList(reportData, rowValuesExtractor, columnTitles) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {rowTitle?.map((rowItemTitle) => (
              <TableCell>{rowItemTitle}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {reportData.map((row) => (
            <TableRow>
              {rowValuesExtractor(row).map((rowItemValue) => (
                <TableCell>{rowItemValue}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
 */
