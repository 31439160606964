import React, { useContext } from "react";
import { DataProviderContext, SaveButton, useNotify, useRedirect } from "react-admin";
import { useFormState } from "react-final-form";
import { AuthInfoContext } from "../../hooks/useAuthInfo";

const CustomSaveButton = (formProps) => {
  const {authInfo} = useContext(AuthInfoContext);
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useContext(DataProviderContext);
  let { values } = useFormState();
  const [saving, setSaving] = React.useState(false);

  //massage data
  var request = {
    ...values,
    tenant_id: authInfo.tenant.id,
  };

  const handleCreateClick = () => {
    setSaving(true);

    dataProvider
      .create(formProps.resource, { data: { ...request } })
      .then(({ data }) => {
        redirect(`/cancellation_policies`);
        notify("Cancellation Policy created!");
      })

      .catch((error) => {
        setSaving(false);
        notify(error);
      });
  };

  const handleUpdateClick = () => {
    setSaving(true);

    dataProvider
      .update(formProps.resource, { id: request.id, data: { ...request } })
      .then(({ data }) => {
        redirect(`/cancellation_policies`);
        notify("Cancellation Policy updated!");
      })

      .catch((error) => {
        setSaving(false);
        notify(error);
      });
  };

  return (
    <SaveButton
      {...formProps}
      saving={saving}
      disabled={saving}
      onSave={values.id != undefined ? handleUpdateClick : handleCreateClick}
    />
  );
};

export default CustomSaveButton;
