import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceInput,
  SelectInput,
  TextInput,
  FunctionField,
  downloadCSV
} from 'react-admin';
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import { roomTypeStatus } from "../../models/roomTypeStatus";
import Chip from '@material-ui/core/Chip';
import jsonExport from 'jsonexport/dist';
import { useContext } from "react";
import { AuthInfoContext } from "../../hooks/useAuthInfo";


const filters = [
  <TextInput
    source="name"
    label="Search"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    style={{ width: 250 }}
    label="Type"
    source="type"
    reference="e_room_types"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
  <ReferenceInput
    style={{ width: 250 }}
    label="Status"
    source="status"
    reference="e_room_type_status"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>
];

const exporter = records => {
  const forExport = records.map(record => {
    return {
      name: record.name,
      type: record.e_room_type.comment,
      area: record.area,
      max_occupancy: record.max_occupancy,
      status: record.e_room_type_status.comment,
    };
  });
  jsonExport(forExport, {
    headers: ['name', 'type', 'area', 'max_occupancy', 'status']
  }, (err, csv) => {
    downloadCSV(csv, 'room_types');
  });
};

const RoomTypeList = props => {
  const {authInfo} = useContext(AuthInfoContext);
  return (
    <List sort={{ field: 'created_at', order: 'Desc' }} filters={filters} filterDefaultValues={{ tenant_id: authInfo.tenant.id }} bulkActionButtons={false} exporter={exporter} {...props}>
      <Datagrid>
        <TextField source="name" label="ra.column.name" />
        <TextField source="e_room_type.comment" label="Type" />
        <TextField source="area" label="Area (square feet)" />
        <TextField source="max_occupancy" label="Max occupancy (person)" />
        <FunctionField sortBy="status" label="Status" render={record => <Chip label={record.e_room_type_status?.comment} style={{ color: 'white', background: roomTypeStatus.getColor(record.e_room_type_status?.id) }} />} />
        <EditButton style={{ float: "right" }} basePath="/room_types" />
      </Datagrid>
    </List>
  )
}

export default RoomTypeList;
