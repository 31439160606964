import React from "react";
import { Button } from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import { useContext } from "react";
import { AuthInfoContext } from "../../hooks/useAuthInfo";

const LogoutButton = props => {
    const notify = useNotify();
    const [loading, setLoading] = useSafeSetState(false);
    const { clearAuth } = useContext(AuthInfoContext);
    const handleLogout = () => {
        setLoading(true);
        if (window.$chatwoot) {
            window.$chatwoot.reset()
        }
        localStorage.removeItem('bleudine-user');

        localStorage.removeItem('auth');
        localStorage.removeItem('token');
        clearAuth().then(() => {
            setLoading(false);
            window.location.href = "/"
        })
    }

    return (
        <Button
            style={{ display: "-webkit-box", color: "#0000008a", marginLeft: 10, width: "100%" }}
            startIcon={<div style={{ display: "inline-flex", marginLeft: 12, marginRight: 10 }}><ExitToAppIcon /></div>}
            onClick={handleLogout}
        >
            Logout
        </Button>
    )
};

export default (LogoutButton);
