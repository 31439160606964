import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceInput,
  SelectInput,
  TextInput,
  FunctionField,
  downloadCSV
} from 'react-admin';
import { roomStatus } from "../../models/roomStatus";
import Chip from '@material-ui/core/Chip';
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import jsonExport from 'jsonexport/dist';
import { useContext } from 'react';
import { AuthInfoContext } from '../../hooks/useAuthInfo';


const exporter = records => {
  const forExport = records.map(record => {
    return {
      name: record.name,
      floor: record.floor,
      area: record.room_type.area,
      type: record.room_type.name,
      status: record.e_room_status.comment,
    };
  });
  jsonExport(forExport, {
    headers: ['name', 'floor', 'area', 'type', 'status']
  }, (err, csv) => {
    downloadCSV(csv, 'rooms');
  });
};

const RoomList = props => {
  const {authInfo} = useContext(AuthInfoContext);

  const filters = [
    <TextInput
      source="name,floor"
      label="Search"
      alwaysOn
      resettable
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
    />,
    <ReferenceInput
      style={{ width: 250 }}
      label="Type"
      source="room_type_id"
      reference="room_types"
      alwaysOn
      resettable
      filterToQuery={() => ({ tenant_id: authInfo.tenant.id })}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>,
    <ReferenceInput
      style={{ width: 250 }}
      label="Status"
      source="status"
      reference="e_room_status"
      alwaysOn
      resettable
    >
      <SelectInput optionText="comment" />
    </ReferenceInput>,
  ];

  return (
    <List sort={{ field: 'floor', order: 'Asc' }} filters={filters} filterDefaultValues={{ tenant_id: authInfo.tenant.id }} bulkActionButtons={false} exporter={exporter}  {...props}>
      <Datagrid>
        <TextField source="name" label="ra.column.name" />
        <TextField source="floor" label="Floor" />
        <TextField source="room_type.area" label="Area (square feet)" />
        <TextField source="room_type.name" label="Type" />
        <FunctionField sortBy="status" label="Status" render={record => <Chip label={record.e_room_status?.comment} style={{ color: 'white', background: roomStatus.getColor(record.e_room_status?.id) }} />} />
        <EditButton style={{ float: "right" }} basePath="/rooms" />
      </Datagrid>
    </List>
  );
}

export default RoomList;
