import { useState } from 'react';
import { createContext, useEffect } from 'react';

export const AuthInfoContext = createContext();

export const INDEXEDDB_VERSION = 1;
export const DB_NAME = "auth";
export const OBJECTSTORE_NAME = "auth";

export const DB_MIGRATION = (db, version) => {
    if (version < 1) {
        db.createObjectStore(OBJECTSTORE_NAME);
    }
};

export function checkDbAuth() {
    return new Promise((resolve, reject) => {
        const req = window.indexedDB.open(DB_NAME, INDEXEDDB_VERSION);
        req.onupgradeneeded = (evt) => {
            const db = evt.target.result;
            db.onerror = () => reject("Failed to create database");
            DB_MIGRATION(db, evt.oldVersion)
        };
        req.onsuccess = (evt) => {
            const db = evt.target.result;
            const txn = db.transaction(OBJECTSTORE_NAME, "readonly");
            const store = txn.objectStore(OBJECTSTORE_NAME);
            const readreq = store.get("me");
            readreq.onsuccess = (evt) => resolve(!!evt.target.result);
            readreq.onerror = (e) => reject(e);
        };
    });
}

const useIndexedDB = (name, version, migrate) => {

    const createDB = () => {
        return new Promise((resolve, reject) => {
            const req = window.indexedDB.open(name, version);
            req.onupgradeneeded = (evt) => {
                const db = evt.target.result;
                db.onerror = () => reject("Failed to create database");
                migrate(db, evt.oldVersion)
            };
            req.onsuccess = (evt) => {
                resolve()
            };
        });
    };

    const objectStore = (storeName) => {
        return new Promise((resolve, reject) => {
            const req = window.indexedDB.open(name, version);

            req.onsuccess = (evt) => {
                const db = evt.target.result;
                const txn = db.transaction(storeName, "readwrite");
                const store = txn.objectStore(storeName);
                resolve(store)
            };
            req.onerror = () => reject()
        });
    };

    return { createDB, objectStore }

};

export const useDbAuthInfo = () => {
    const { createDB, objectStore } = useIndexedDB(DB_NAME, INDEXEDDB_VERSION, DB_MIGRATION);
    const [reload, setReload] = useState(true);
    const [authInfo, setAuthInfo] = useState(undefined);
    useEffect(() => {
        createDB().catch(e => console.log("error"));
    }, []);
    const getAuthInfo = () => {
        return new Promise((resolve, reject) => {
            objectStore(OBJECTSTORE_NAME)
                .then(store => {
                    const req = store.get("me");
                    req.onsuccess = (rec) => resolve(rec.target.result);
                    req.onerror = () => reject("failed to get record");
                }).catch(e => reject(e));
        })
    }
    useEffect(() => {
        if (reload) {
            getAuthInfo().then(setAuthInfo).finally(() => setReload(false));
        }
    }, [reload]);


    const updateAuth = (auth) => {
        return new Promise((resolve, reject) => {
            setAuthInfo(undefined);
            objectStore(OBJECTSTORE_NAME).then(store => {
                const req = store.put(auth, "me")
                req.onsuccess = (evt) => {
                    resolve(evt.target.result);
                };
                req.onerror = (e) => reject(e);
            }).catch(e => reject(e))
                .finally(() => setReload(true));
        });
    };


    const clearAuth = () => {
        return new Promise((resolve, reject) => {
            objectStore(OBJECTSTORE_NAME)
                .then(store => {
                    const req = store.clear()
                    req.onsuccess = () => resolve();
                    req.onerror = () => resolve();
                }).catch(reject);
        });

    }


    return { updateAuth, clearAuth, authInfo }
};
