import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Input, Paper, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DataProviderContext, useNotify } from 'react-admin';
import { bookingApi } from '../../api/bookingApi';
import { roomRateApi } from '../../api/roomrateApi';
import { AuthInfoContext } from '../../hooks/useAuthInfo';
import { formatter } from '../../utils/formatter';
import "./DynamicRoomRate.css";
const DynamicRateContext = React.createContext({})


function MonthPicker({ month, onNext, onPrev }) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']


    return <div className="month-picker">
        <button className="icon-button" style={{ cursor: "pointer" }} onClick={onPrev}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
        </button>
        <span>{months[month]}</span>
        <button className="icon-button" style={{ cursor: "pointer" }} onClick={onNext} >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
        </button>
    </div>
}

function YearSelection({ selections, onSelect, open, setOpen, selectedYear }) {
    const dropDownRef = useRef(undefined);
    useEffect(() => {
        const clickOutside = (event) => {
            if (dropDownRef && dropDownRef.current) {
                if (open) {
                    if (!dropDownRef.current.contains(event.target)) {
                        setOpen(false)
                    }
                }
            }
        }
        window.addEventListener('click', clickOutside);
        if (dropDownRef.current) {
            let dom = dropDownRef.current.querySelector(`[data-year="${selectedYear}"]`)
            dom.scrollIntoView({ block: "nearest", inline: "nearest", behavior: "smooth" })
        }

        return () => window.removeEventListener('click', clickOutside)
    }, [open, selectedYear]);



    return open ? <div className='year-selections' ref={dropDownRef}>
        {selections.map((y) => <div data-year={y} key={y} className={`year-selection ${selectedYear == y ? 'selected-year' : undefined}`} onClick={() => { onSelect(y); setOpen(false); }} >{y}</div>)}
    </div> : <></>


}


function YearPicker({ year, onNext, onPrev }) {
    const { setSelectedYear } = useContext(DynamicRateContext)
    const [yearSelection, setYearSelection] = useState([]);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setYearSelection(yearRange(year - 10, year + 11))
    }, [year])
    return <div className="year-picker">
        <YearSelection selectedYear={year} selections={yearSelection} onSelect={setSelectedYear} open={open} setOpen={setOpen} />
        <button className="icon-button" style={{ cursor: "pointer" }} onClick={onPrev}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
        </button>
        <span className="year-text" onClick={()=>setOpen(true)}>{year}</span>
        <button className="icon-button" style={{ cursor: "pointer" }} onClick={onNext} >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
        </button>
    </div>
}

function DayHeader({ day }) {

    return <div className="calendar-body-header-item">{day}</div>
}

function FillerDay() {
    return <div className='calendar-filler'></div>
}

function CalendarDay({ date, past = false, active = false, children }) {

    return <div data-date={date} className={`calendar-day ${past ? 'past-calendar-day' : ''}`} style={active ? { backgroundColor: '#f0f0ff' } : undefined}>
        <span className="calendar-date-indicator">{new Date(date).getDate()}</span>
        {children && <div className="calendar-day-event-list">
            {children}
        </div>}
    </div>
}


function yearRange(lower, upper) {
    if (lower > upper) {
        return []
    }

    let result = []
    for (let i = lower; i < upper; i++) {
        result.push(i);
    }
    return result;
}

function range(limit) {
    let result = []
    for (let i = 0; i < limit; i++) {
        result.push(i);
    }
    return result;
}

function rangeDate(year, month) {
    let endOfMonth = new Date(year, month + 1, 0);
    let lastDate = endOfMonth.getDate();
    let result = [];
    for (let i = 1; i <= lastDate; i++) {
        result.push(year + '-' + (month + 1).toString(10).padStart(2, '0') + '-' + i.toString(10).padStart(2, '0'));
    }
    return result;

}

function dateSequence(startDate, endDate) {
    if (!endDate || moment(startDate, 'YYYY-MM-DD').isAfter(moment(endDate, 'YYYY-MM-DD'))) {
        return []
    }
    let start = startDate;
    let result = []
    let upperBound = moment(endDate, 'YYYY-MM-DD').add(1, 'days').format("YYYY-MM-DD")
    while (start != upperBound) {
        result.push(start);
        start = moment(start, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
    }
    return result;

}

function CalendarBody({ year, month, events }) {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const [dates, setDates] = useState([]);
    const endOfMonth = new Date(year, month + 1, 0);
    let lastDay = endOfMonth.getDay();
    let firstDay = new Date(year, month, 1).getDay();

    useEffect(() => {
        setDates(rangeDate(year, month))
    }, [year, month])
    return <div className="calendar-body">
        {days.map((day) => <DayHeader key={day} day={day} />)}
        {range(firstDay).map((filler) => <FillerDay key={filler} />)}
        {dates.map((date) => {
            return <CalendarDay date={date} key={date} past={moment(date, 'YYYY-MM-DD').isBefore(moment(new Date()).startOf('day'))} active={date == moment(new Date()).format('YYYY-MM-DD')}>
                {events && events(date)}
            </CalendarDay>
        })}
        {range(7 - (lastDay + 1)).map((filler) => <FillerDay key={filler} />)}
    </div>
}

function Calendar({ year, month, onNext, onPrev, onNextYear, onPrevYear, renderEvents }) {
    return <div className="calendar-container">
        <header className="calendar-header">
            <YearPicker year={year} onNext={onNextYear} onPrev={onPrevYear} />
            <MonthPicker month={month} onNext={onNext} onPrev={onPrev} />
        </header>
        <CalendarBody year={year} month={month} events={renderEvents} />
    </div>

}

function RoomRateSearchPanel({ onRoomRateSelected }) {
    const { availableRoomRates } = useContext(DynamicRateContext);
    const updateSelectedRoomRate = (roomRateId) => {
        if (roomRateId == "")
            return

        if (onRoomRateSelected) {
            onRoomRateSelected(availableRoomRates.find(rec => rec.id === roomRateId))
        }
    }
    return <Box paddingBottom={4} marginBottom={2}>
        <FormControl fullWidth>
            <InputLabel>Room rate</InputLabel>
            <Select label="Room rate" native onChange={evt => updateSelectedRoomRate(evt.target.value)}>
                <option value="" aria-label="none"></option>
                {availableRoomRates.map(roomRate => <option key={roomRate.id} value={roomRate.id}>{roomRate.name}</option>)}
            </Select>
        </FormControl>
    </Box>
}

const stringToColor = (input) => {
    let hash = input.split("").reduce((acc, curr) => curr.charCodeAt(0) + (acc << 5) - acc, 0)
    let color = "#";
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += value.toString(16).padStart(2, '0');
    }
    return color;
}
function RoomTypeLegend({ roomTypes }) {
    return <Paper>
        <Box padding={2}>
            <Grid spacing={2} container direction={'column'} style={{ width: "100%" }}>
                <Grid item>
                    <header><Typography variant='h6'>Legends</Typography></header>
                </Grid>
                <Grid item>
                    <section>
                        <div style={{ display: "flex", minWidth: "50%", gap: "1rem" }}>
                            {roomTypes.map(roomType =>
                                <Fragment key={roomType.id}>
                                    <div className="room-type-indicator" style={{ backgroundColor: stringToColor(roomType.id), minHeight: "1.5rem" }}></div>
                                    <span>{roomType.name}</span>
                                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                                        <span style={{ border: "solid thin #aaa", padding: "0.1rem" }}>Default</span>
                                        <span style={{ border: "solid thin #aaa", padding: "0.1rem", fontWeight: "bold", color: "#fff", backgroundColor: stringToColor(roomType.id) }}>Dynamic</span>
                                    </div>
                                </Fragment>)}
                        </div>
                    </section>
                </Grid>
            </Grid>
        </Box>
    </Paper>
}

function RateRecord({ children, color, dynamic, editable = true, onClick }) {
    return <div className="calendar-day-event-item" style={{ cursor: editable ? 'pointer' : 'not-allowed' }} onClick={onClick}><span className="room-type-indicator" style={{ backgroundColor: color }}></span><span className="room-rate-value" style={dynamic && { backgroundColor: color, fontWeight: 'bold', color: "#fff" }}>{children}</span></div>
}


function useForm(initialValue, validators) {
    const [formData, setFormData] = useState({})
    const [touchedFields, setTouchedFields] = useState([])
    const [errors, setErrors] = useState({});
    const [valid, setValid] = useState(false);

    useEffect(() => {
        setFormData(initialValue)
        setTouchedFields([])
        setErrors({})
        setValid(false)
    }, [initialValue])
    useEffect(() => {
        setValid(() => (touchedFields.length > 0) && Object.keys(errors).reduce((acc, key) => (acc && !errors[key]), true))
    }, [errors, touchedFields])

    useEffect(() => {
        Object.keys(validators).forEach((fieldName) => {
            if (touchedFields.indexOf(fieldName) > -1) {
                let error = validators[fieldName](formData[fieldName], formData)
                setErrors((err) => ({ ...err, [fieldName]: error }))
            }
        });

    }, [formData, touchedFields])
    const update = (evt) => {
        if (!touchedFields[evt.target.name]) {
            setTouchedFields(old => ([...old, evt.target.name]))
        }
        setFormData(oldFormData => ({ ...oldFormData, [evt.target.name]: evt.target.value }))
    }

    return { form: formData, update, valid, errors }

}


function DynamicRateFormModal({ open, onRemove, onClose, onSave }) {
    const { roomTypes, selectedRoomRate, selectedRate } = useContext(DynamicRateContext)
    let roomTypeRates = selectedRoomRate.room_type_rates.flatMap((roomTypeRate) => ({ id: roomTypeRate.id, value: roomTypes.find(roomType => roomType.id === roomTypeRate.room_type_id)?.name }))
    const [formData, setFormData] = useState({})
    const [isRange, setIsRange] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const validators = {
        room_type: (value) => {
            if (!value) {
                return "Select a room type"
            }
            return "";
        },
        from_date: (value, formData) => {
            if (!value) {
                return "Input a valid date"
            }
            if (range && !formData.to_date) {
                return "Invalid date range"
            }
            if (moment(value, "YYYY-MM-DD").isSameOrAfter(moment(formData.to_date, "YYYY-MM-DD"))) {
                return "Invalid date range"
            }
            return ""

        },
        to_date: (value, formData) => {
            if (!value) {
                return "Input a valid date"
            }
            if (formData && !formData.from_date) {
                return "Invalid date range"
            }

            if (moment(formData.from_date, "YYYY-MM-DD").isSameOrAfter(moment(value, "YYYY-MM-DD"))) {
                return "Invalid date range"
            }
            return ""
        },
        value: (value) => {
            if (!value || Number.isNaN(value) || value <= 0) {
                return "Invalid rate"
            }

            return ""
        }
    }


    useEffect(() => {
        if (selectedRate) {
            setFormData({ from_date: selectedRate.date, room_type: selectedRate.roomTypeRateId, value: selectedRate.price })
            setIsUpdate(selectedRate.isUpdate)
        }
    }, [selectedRate])

    const { form, update, valid, errors } = useForm(formData, validators)


    return <Dialog open={open} fullWidth>
        <DialogTitle>
            {`Configure dynamic room rate`}
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2} direction='column'>
                <Grid item>
                    <FormControl fullWidth error={errors.room_type}>
                        <InputLabel shrink>Room type</InputLabel>
                        <Select disabled={isUpdate} value={form.room_type || ""} name="room_type" onChange={update}>
                            <option value="" />
                            {roomTypeRates.map(roomTypeRate => <option value={roomTypeRate.id} key={roomTypeRate.id}>{roomTypeRate.value}</option>)}
                        </Select>
                        {errors.room_type ? <FormHelperText>{errors.room_type}</FormHelperText> : undefined}
                    </FormControl>
                    <FormControl fullWidth error={errors.value}>
                        <InputLabel shrink >Rate</InputLabel>
                        <Input type="number" name="value" value={form.value || ''} onInput={update} />
                        {errors.value ? <FormHelperText>{errors.value}</FormHelperText> : undefined}
                    </FormControl>
                    <FormControlLabel label="Date range" control={<Switch checked={isRange} onChange={() => setIsRange(r => !r)} />} />
                    {isRange ?
                        (<><FormControl fullWidth error={errors.from_date}>
                            <InputLabel variant='standard' shrink>From date</InputLabel>
                            <Input type="date" name="from_date" value={form.from_date || ''} onInput={update} />
                            {errors.from_date ? <FormHelperText>{errors.from_date}</FormHelperText> : undefined}
                        </FormControl>
                            <FormControl fullWidth error={errors.to_date}>
                                <InputLabel variant='standard' shrink>To date</InputLabel>
                                <Input type="date" name="to_date" value={form.to_date || ''} onInput={update} />
                                {errors.to_date ? <FormHelperText>{errors.to_date}</FormHelperText> : undefined}
                            </FormControl></>) : <FormControl fullWidth error={errors.from_date}>
                            <InputLabel shrink>Date</InputLabel>
                            <Input type="date" name="from_date" value={form.from_date || ''} onInput={update} />
                            {errors.from_date ? <FormHelperText>{errors.from_date}</FormHelperText> : undefined}
                        </FormControl>}
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant="text" color='default' onClick={() => {
                setIsRange(false);
                setIsUpdate(false);
                if (onClose) {
                    onClose()
                }
            }}>Cancel</Button>
            {(isUpdate && !isRange) ? <Button onClick={onRemove ? () => onRemove({ date: form.from_date, room_type: form.room_type }) : undefined} style={{ display: "flex", color: "#aa2222", gap: "0.4rem", justifyContent: 'center', alignItems: 'center' }} variant='contained' color='default'>
                <span>Remove</span>
                <svg style={{ minWidth: "1rem" }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>
            </Button> : undefined}
            <Button variant='contained' color='primary' disabled={!valid} onClick={() => {
                onSave(form);
                setIsRange(false);
                setIsUpdate(false);
            }}>Update</Button>
        </DialogActions>
    </Dialog>
}


export default function DynamicRoomRateList() {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    /**
        * @typedef {{room_type_rates: Array<{id: string, price: number, room_type_id: string, room_type_rate_adjustments: Array<{id:string, date: string, value: number}>}>}} RoomRate
        * @type {[RoomRate, function(RoomRate | function(oldValue:RoomRate):RoomRate):void]}
        * */
    const [selectedRoomRate, setSelectedRoomRate] = useState();
    const [selectedDynamicRates, setSelectedDynamicRates] = useState([])
    const [availableRoomTypes, setAvailableRoomTypes] = useState([]);
    const [availableRoomRates, setAvailableRoomRates] = useState([]);
    const [roomTypes, setRoomTypes] = useState([]);
    const [events, setEvents] = useState({});
    const [selectedRate, setSelectedRate] = useState({})
    const [reload, setReload] = useState(false);
    const dataProvider = useContext(DataProviderContext);
    const notify = useNotify()
    const {authInfo} = useContext(AuthInfoContext);
    

    useEffect(() => {
        dataProvider.getList("room_types", { filter: { tenant_id: authInfo.tenant.id } })
            .then(({ data }) => setAvailableRoomTypes(data)).catch(notify);
        dataProvider.getList("room_rates", { filter: { tenant_id: authInfo.tenant.id } })
            .then(({ data }) => setAvailableRoomRates(data)).catch(error => notify(error));
    }, [])

    useEffect(() => {
        if (selectedRoomRate) {
            const startOfMonth = moment(new Date(selectedYear, selectedMonth, 1)).format("YYYY-MM-DD")
            const endOfMonth = moment(new Date(selectedYear, selectedMonth + 1, 0)).format("YYYY-MM-DD")

            let roomTypeIds = selectedRoomRate.room_type_rates.map(roomTypeRate => roomTypeRate.room_type_id);
            setRoomTypes(availableRoomTypes.filter(roomType => roomTypeIds.indexOf(roomType.id) != -1));
            bookingApi.getDynamicRates(selectedRoomRate.room_type_rates.map(roomTypeRate => roomTypeRate.id), startOfMonth, endOfMonth)
                .then(({ data }) => {
                    if (data.room_type_rate_adjustment && data.room_type_rate_adjustment.length > 0) {
                        setSelectedDynamicRates(data.room_type_rate_adjustment);

                    } else {
                        setSelectedDynamicRates([])
                    }
                })
                .catch(notify);
        } else {
            setRoomTypes([])
        }
    }, [selectedRoomRate, selectedYear, selectedMonth])

    useEffect(() => {
        if (reload) {
            const startOfMonth = moment(new Date(selectedYear, selectedMonth, 1)).format("YYYY-MM-DD")
            const endOfMonth = moment(new Date(selectedYear, selectedMonth + 1, 0)).format("YYYY-MM-DD")


            bookingApi.getDynamicRates(selectedRoomRate.room_type_rates.map(roomTypeRate => roomTypeRate.id), startOfMonth, endOfMonth)
                .then(({ data }) => {
                    if (data.room_type_rate_adjustment && data.room_type_rate_adjustment.length > 0) {
                        setSelectedDynamicRates(data.room_type_rate_adjustment);

                    } else {
                        setSelectedDynamicRates([])
                    }
                })
                .catch(notify)
                .finally(() => setReload(false));

        }
    }, [reload, selectedYear, selectedMonth])


    useEffect(() => {
        if (roomTypes) {
            /**
                * @type {Object<string,number>}
                * */
            let fallback = roomTypes.reduce((acc, curr) => ({
                ...acc, [curr.id]: { price: selectedRoomRate.room_type_rates.find(roomTypeRate => roomTypeRate.room_type_id === curr.id)?.price, roomTypeRateId: selectedRoomRate.room_type_rates.find(roomTypeRate => roomTypeRate.room_type_id == curr.id)?.id }
            }), {});
            let events = { fallback }

            if (selectedDynamicRates && selectedDynamicRates.length > 0) {
                let roomTypeRateMap = selectedRoomRate.room_type_rates.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.room_type_id }), {})
                let dynamicEvents = selectedDynamicRates.filter(rate => roomTypeRateMap[rate.room_type_rate_id]).reduce((acc, rate) => ({ ...acc, [rate.date]: { ...acc[rate.date], [roomTypeRateMap[rate.room_type_rate_id]]: { price: rate.value, roomTypeRateId: rate.room_type_rate_id } } }), {})
                events = { ...events, ...dynamicEvents }
            }
            setEvents(events)
        }



    }, [roomTypes, selectedRoomRate, selectedDynamicRates])



    const eventRenderer = useMemo(() => {
        return (date) => {
            let today = moment().startOf("day")
            if (events) {
                let editable = moment(date, 'YYYY-MM-DD').isSameOrAfter(today)
                let event = events[date];
                let fallback = events.fallback;
                const handleClick = (date, roomTypeId, roomTypeRateId, price = undefined, isUpdate = false) => () => {
                    setSelectedRate({ date, roomTypeId, roomTypeRateId, price, isUpdate })
                    setOpenModal(true)
                }


                if (event) {
                    const dynamicRoomTypeId = Object.keys(event)
                    const statics = Object.keys(fallback).filter(key => dynamicRoomTypeId.indexOf(key) == -1)
                    return <>
                        {statics.map(roomTypeId => <RateRecord editable={editable} onClick={editable ? handleClick(date, roomTypeId, fallback[roomTypeId].roomTypeRateId, fallback[roomTypeId].price) : undefined} color={stringToColor(roomTypeId)} key={roomTypeId}>{formatter.formatMoney(fallback[roomTypeId].price, authInfo.tenant.locale.currency_code, authInfo.tenant.locale.precision)}</RateRecord >)
                        }
                        {Object.keys(event).map(roomTypeId => <RateRecord editable={editable} onClick={editable ? handleClick(date, roomTypeId, event[roomTypeId].roomTypeRateId, event[roomTypeId].price, true) : undefined} dynamic color={stringToColor(roomTypeId)} key={roomTypeId}>{formatter.formatMoney(event[roomTypeId].price, authInfo.tenant.locale.currency_code, authInfo.tenant.locale.precision)}</RateRecord>)}
                    </>

                } else if (fallback) {
                    return <>{Object.keys(fallback).map(roomTypeId => <RateRecord editable={editable} onClick={editable ? handleClick(date, roomTypeId, fallback[roomTypeId].roomTypeRateId, fallback[roomTypeId].price) : undefined} color={stringToColor(roomTypeId)} key={roomTypeId}>{formatter.formatMoney(fallback[roomTypeId].price, authInfo.tenant.locale.currency_code, authInfo.tenant.locale.precision)}</RateRecord>)}</>

                }
                return undefined

            } else {
                return undefined
            }
        }
    }, [events])


    const nextMonth = () => {
        setSelectedMonth((oldVal) => {
            if (oldVal == 11) {
                setSelectedYear(year => year + 1)
                return 0
            }
            return oldVal + 1
        })

    }
    const prevMonth = () => {
        setSelectedMonth((month) => {
            if (month == 0) {
                setSelectedYear(year => year - 1);
                return 11;
            }
            return month - 1;

        })
    }


    const [openModal, setOpenModal] = useState(false)

    const onClose = () => { setOpenModal(false); setSelectedRate({}); }
    const onSave = (formData) => {
        const { from_date, to_date, value, room_type } = formData;
        const dates = dateSequence(from_date, to_date || from_date)
        let request = dates.map(date => ({ date, value, room_type_rate_id: room_type }))
        roomRateApi.upsertRoomTypeRateAdjustment(request)
            .then(({ data }) => setReload(true)).catch(notify).finally(onClose);

    }

    const onRemove = (formData) => {
        roomRateApi.removeRoomTypeRateAdjustment({ room_type_rate_id: formData.room_type, date: formData.date })
            .then(_ => setReload(true)).catch(notify).finally(onClose)

    }


    return  (availableRoomRates.length == 0) ?<ErrorAlert errorMessage={"No room rates available, please create a room rate before proceed to configure dynamic rates."} />: <DynamicRateContext.Provider value={{ selectedRoomRate, roomTypes, availableRoomRates, selectedRate, setSelectedRate, selectedYear, setSelectedYear }}>
        <Box padding={2} width={1}>
            <Grid spacing={2} container direction='column'>
                <Grid item xs={2}><RoomRateSearchPanel onRoomRateSelected={setSelectedRoomRate} /></Grid>
                {selectedRoomRate && <>
                    <DynamicRateFormModal open={openModal} onRemove={onRemove} onSave={onSave} onClose={onClose} />

                    <Box xs={2} paddingBottom={2} marginRight={1}>
                        <RoomTypeLegend roomTypes={roomTypes} />
                    </Box>
                    <Box width={1} padding={1} marginBottom={1} marginRight={1}>
                        <Grid container justifyContent="flex-end" alignItems='flex-end'>
                            <Button variant='contained' color="primary" onClick={() => setOpenModal(true)}>Add Dynamic Rate</Button>
                        </Grid>
                    </Box>
                    <Box width={1}>
                        <Card>
                            <CardContent>
                                <Calendar year={selectedYear} month={selectedMonth} onNext={nextMonth} onPrev={prevMonth} onNextYear={() => setSelectedYear(year => year + 1)} onPrevYear={() => setSelectedYear(year => year - 1)} renderEvents={eventRenderer} />
                            </CardContent>
                        </Card>
                    </Box>
                </>}
            </Grid>
        </Box>
    </DynamicRateContext.Provider>

}
const ErrorAlert = ({ errorMessage, onDismiss }) => {
  if (!errorMessage) return null;

  return (
    <div style={{
      backgroundColor: '#D32F2F',
      color: 'white',
      padding: '10px',
      borderRadius: '5px',
      margin: '10px 0',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}>
      {errorMessage}
      {onDismiss ?
      <button onClick={onDismiss} style={{
        marginLeft: '20px',
        backgroundColor: 'transparent',
        border: 'none',
        color: 'white',
        cursor: 'pointer',
      }}>
        ✕
      </button> : undefined}
    </div>
  );
};
