import gql from 'graphql-tag';

export const GET_LIST_CM_SYNC_TASKS = gql`
{
  id
  status
  cm_client {
    name
  }
  description
  remark
}
`;

export default {
    GET_LIST_CM_SYNC_TASKS
}