import { Button } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import React, { useContext, useEffect } from "react";
import {
  DataProviderContext,
  FormWithRedirect,
  Title,
  useNotify,
  useTranslate
} from "react-admin";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { roomCleanStatus } from "../../models/roomCleanStatus";

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subCardWrap: {
    minHeight: 260,
  },
}));

const Housekeeping = (props) => {
  const { authInfo } = useContext(AuthInfoContext);

  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);
  const [rooms, setRooms] = React.useState([]);



  useEffect(() => {
    if (authInfo.tenant) {
      var roomRequest = {
        tenant_id: authInfo.tenant?.id,
      };
      dataProvider
        .getList("rooms", {
          filter: { ...roomRequest },
          sort: { field: "name", order: "ASC" },
        })
        .then(({ data }) => {
          setRooms(data);
        })
        .catch((error) => {
          notify(error);
        });


    }

  }, [authInfo.tenant]);

  const refreshRooms = () => {
    var roomRequest = {
      tenant_id: authInfo?.tenant?.id,
    };
    dataProvider
      .getList("rooms", {
        filter: { ...roomRequest },
        sort: { field: "name", order: "ASC" },
      })
      .then(({ data }) => {
        data.sort((a, b) => (a.name > b.name ? 1 : -1));
        setRooms(data);
      })
      .catch((error) => {
        notify(error);
      });
  };

  const handleUpdateHouseKeeping = (room) => {
    let clean_status = "Dirty";
    if (room.clean_status == "Dirty") {
      clean_status = "Clean";
    }

    dataProvider
      .update("rooms", {
        id: room.id,
        data: { clean_status },
      })
      .then(({ data }) => {
        refreshRooms();
        notify("Housekeeping updated");
      })
      .catch((error) => {
        notify(error);
      });
  };

  return (
    <div>
      <Title title={translate("ra.label.housekeeping")} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Room No</TableCell>
                  <TableCell>Floor</TableCell>
                  <TableCell>Area</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rooms?.map((row) => (
                  <FormWithRedirect
                    onSubmit={() => { }}
                    render={(formProps) => (
                      <TableRow>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.floor}</TableCell>
                        <TableCell>{row.room_type.area}</TableCell>
                        <TableCell>{row.room_type.name}</TableCell>
                        <TableCell>
                          <Chip
                            label={row.e_room_clean_status.comment}
                            style={{
                              color: "white",
                              background: roomCleanStatus.getColor(
                                row.e_room_clean_status.id
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            color="primary"
                            startIcon={
                              row.clean_status == "Dirty" ? (
                                <CheckIcon />
                              ) : (
                                <AddIcon />
                              )
                            }
                            onClick={() => handleUpdateHouseKeeping(row)}
                          >
                            {row.clean_status == "Dirty"
                              ? "Complete"
                              : "Request"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default Housekeeping;
