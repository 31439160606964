import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  EditButton,
  downloadCSV
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import jsonExport from 'jsonexport/dist';
import { useContext } from 'react';
import { AuthInfoContext } from '../../hooks/useAuthInfo';


const exporter = records => {
  const forExport = records.map(record => {
    return {
      name: record.name,
      floor: record.floor,
      area: record.room_type.area,
      type: record.room_type.name,
      status: record.e_room_status.comment,
    };
  });
  jsonExport(forExport, {
    headers: ['name', 'period_cancel_without_charge', 'after_period_cancel_charge', 'no_show_cancel_charge']
  }, (err, csv) => {
    downloadCSV(csv, 'rooms');
  });
};

const CancelList = props => {
  const {authInfo} = useContext(AuthInfoContext)

  const filters = [
    <TextInput
      source="name"
      label="Search"
      alwaysOn
      resettable
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
    />
  ];


  return (
    <List sort={{ field: 'name', order: 'Asc' }} filters={filters} filterDefaultValues={{ tenant_id: authInfo.tenant.id }} bulkActionButtons={false} exporter={exporter}  {...props}>
      <Datagrid>
        <TextField source="name" label="ra.column.name" />
        <TextField source="period_cancel_without_charge" label="Period Cancel Without Charge" />
        <TextField source="after_period_cancel_charge" label="After Period Cancel Charge" />
        <TextField source="no_show_cancel_charge" label="No Show Cancel Charge" />
        <EditButton style={{ float: "right" }} basePath="/cancellation_policies" />
      </Datagrid>
    </List>
  );
}

export default CancelList;
