import { InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import {
  ReferenceInput,
  SelectInput,
  TextInput
} from "react-admin";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { channelManagerMasterData } from "../../services/channelManagerService";
import { useContext } from "react";

const cmRoomTypeFilters = [
  <TextInput
    source="cm_client_room_type_name"
    label="Client Room Type Name"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    source="cm_client_id"
    reference="cm_clients"
    sort={{ field: "name", order: "ASC" }}
    label="Client"
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

const CMRoomTypeList = (props) => {
  const {authInfo} = useContext(AuthInfoContext);
  const [mapping, setMapping] = useState([]);
  useEffect(() => {
    channelManagerMasterData(authInfo.tenant.id).then(setMapping);
  }, [authInfo.tenant.id]);
  const otas = useMemo(() => {
    return Array.from(new Set(mapping.flatMap(m => m.mapping).map(m => m.ota))).sort()
  }, [mapping]);
  return (<>
    <Paper style={{padding:"0.2rem"}}>
      <header style={{ padding: "0.5rem"}}>
        <Typography variant={"h4"} style={{fontSize:"1.5rem"}}>Room type mapping</Typography>

      </header>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell variant="head">Room Type</TableCell>
            {otas.map(ota => <TableCell key={ota}>{ota}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {mapping.map(m => <TableRow key={m.id}>
            <TableCell variant="body">{m.name}</TableCell>
            {otas.map(ota => <TableCell key={ota}>{m.mapping.find(_m => _m.ota == ota).value}</TableCell>)}
          </TableRow>)}
        </TableBody>
      </Table>
    </Paper>
  </>
  );
};

export default CMRoomTypeList;
