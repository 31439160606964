import { executeAsync } from "../api/hasura";

const channelManagerRoomTypeMap = `query ChannelManagerRoomTypeMap($tenantId: uuid!){
    room_types(where: {tenant_id:{_eq: $tenantId}, status:{_eq:Active}}){
        id
        name
        type
    }
    cm_room_type_maps {
        id
        cm_client_id
       	e_room_type
        cm_client_room_type_name
    }
  	cm_accounts(where:{tenant_id:{_eq:$tenantId}, status:{_eq:"Active"}}){
        cm_client_id
    }
    cm_clients(where:{status: {_eq: Active}}){
        id
        name
    }
} `;

export async function channelManagerMasterData(tenantId) {
    try {
        const { data, errors } = await executeAsync({ tenantId }, channelManagerRoomTypeMap);
        if (errors) {
            throw new Error(errors);
        }
        const { room_types, cm_room_type_maps, cm_accounts,cm_clients } = data;
        if (cm_accounts.length == 0 || room_types.length == 0) {
            return [];
        }
        const client_names = cm_clients.reduce((acc,curr)=>({...acc, [curr.id]:curr.name}),{})
        const configured = cm_accounts.map(m=> m.cm_client_id);
        const mapping = cm_room_type_maps.filter(c=> configured.includes(c.cm_client_id));
        const result = room_types.map(rt=> ({...rt, mapping: mapping.filter(m=> m.e_room_type == rt.type).map(m=> ({ota: client_names[m.cm_client_id], value:m.cm_client_room_type_name }))}));
        return result;
    } catch (e) {
        throw e;
    }

}