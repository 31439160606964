import { apiConfig } from "./apiConfig";
import { handleError, handleResponse } from "./apiHelper";

function query(gql) {
    const url = apiConfig.hasuraUrl;
    const requestOptions = {
        method: "POST",
        headers: {
            //Authorization: `Bearer ${apiConfig.getRideumToken()}`,
            "x-hasura-admin-secret": apiConfig.hasuraSecret,
            "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(gql),
    };

    return fetch(url, requestOptions)
        .then(handleResponse, handleError)
        .then((data) => {
            return data;
        });

}


async function getLocaleByCountryCode(countryCode) {
    return await query({query:`query {
        locales(where: {country: {_eq:${countryCode}}}){
            country
            currency_code
            precision
            decimal_seperator
            thousand_seperator
        }
    }`});
}

export {
    getLocaleByCountryCode
};
