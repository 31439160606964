import { executeAsync } from "../api/hasura";


async function updateIbeRate({ roomRateId, tenantId }) {
    const gql = `mutation updateIbeRate($roomRateId: uuid!, $tenantId: uuid! ){
        update_room_rates(
                    where: {is_ibe: {_eq: true}, tenant_id: {_eq: $tenantId}},
                    _set: {is_ibe:false}){
                    affected_rows
        }
                
        update_room_rates_by_pk(pk_columns:{id:$roomRateId}, _set: {is_ibe:true}){
            id
        }
    }`;
    const { data, errors } = await executeAsync({ roomRateId, tenantId }, gql);

    if (errors) {
        throw new Error(errors);
    }
    return data.update_room_rates_by_pk;
}

async function updateOtaRate({ tenantId, roomRateId }) {
    const gql = `mutation updateOtaRate($roomRateId: uuid!, $tenantId: uuid! ){
        update_room_rates(
                    where: {is_ota: {_eq: true}, tenant_id: {_eq: $tenantId}},
                    _set: {is_ota:false}){
                    affected_rows
        }
                
        update_room_rates_by_pk(pk_columns:{id:$roomRateId}, _set: {is_ota:true}){
            id
        }
    }`;
    const { data, errors } = await executeAsync({ roomRateId, tenantId }, gql);

    if (errors) {
        throw new Error(errors);
    }
    return data.update_room_rates_by_pk;

}

async function disableIbeRate(roomRateId) {
    const gql = `mutation disableIbeRate($roomRateId: uuid!){
        update_room_rates_by_pk(pk_columns:{id: $roomRateId},_set:{is_ibe:false}){
            id
        }
    }`;
    const { data, errors } = await executeAsync({ roomRateId }, gql);
    if (errors) {
        throw new Error(errors);
    }
    return data.update_room_rates_by_pk;
}

async function disableOtaRate(roomRateId){
    const gql = `mutation disableOtaRate($roomRateId: uuid!){
        update_room_rates_by_pk(pk_columns:{id: $roomRateId},_set:{is_ota:false}){
            id
        }
    }`;
    const { data, errors } = await executeAsync({ roomRateId }, gql);
    if (errors) {
        throw new Error(errors);
    }
    return data.update_room_rates_by_pk;
}

export async function setIbeRate({ tenantId, roomRateId }) {
    try {
        const affectedRow = await updateIbeRate({ roomRateId, tenantId });
        if (!affectedRow) {
            throw new Error("failed to update room rate");
        }
        return true;
    } catch (e) {
        console.error(e);
        throw e;
    }

}

export async function setOtaRate({ tenantId, roomRateId }) {
    try {
        const affectedRow = await updateOtaRate({ roomRateId, tenantId });
        if (!affectedRow) {
            throw new Error("failed to update room rate");
        }
        return true;
    } catch (e) {
        console.error(e);
        throw e;
    }
}
export async function unsetIbeRate(roomRateId) {
    try {
        await disableIbeRate(roomRateId);
    } catch (e) {
        throw e;
    }
}
export async function unsetOtaRate(roomRateId){
    try {
        await disableOtaRate(roomRateId);
    } catch (e) {
        throw e;
    }
}