import Chip from "@material-ui/core/Chip";
import * as React from "react";
import {
  Datagrid,
  EditButton,
  EmailField,
  FunctionField,
  List,
  TextField
} from "react-admin";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { guestStatus } from "../../models/guestStatus";
import { useContext } from "react";

const Cm2FASessionsList = (props) => { 
 const {authInfo} = useContext(AuthInfoContext);

  return (
    <List
      sort={{ field: "status", order: "Desc" }}
      filterDefaultValues={{ tenant_id: authInfo.tenant.id, status: "Pending"}}
      bulkActionButtons={false}
      exporter={false}
      {...props}
    >
      <Datagrid>
        <TextField source="cm_account.cm_client.name" label="Client Name" />
        <EmailField source="cm_account.login" label="Email" />
        <TextField source="created_at" label="Created At" />
        <FunctionField
          sortBy="status"
          label="Status"
          render={(record) => (
            <Chip
              label={record.status}
              style={{
                color: "white",
                background: guestStatus.getColor(record.status),
              }}
            />
          )}
        />
        <EditButton style={{ float: "right" }} basePath="/cm_2fa_sessions" />
      </Datagrid>
    </List>
  );
};

export default Cm2FASessionsList;
