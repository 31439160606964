const isLocal = window.location.href.includes("localhost");
const isDev = window.location.href.includes("dev");
const isLocalOrDev = window.location.href.includes("localhost") || window.location.href.includes("dev");
const isStaging = window.location.href.includes("stg");

const bleudineUrl = isLocal ? "http://localhost:50003" : isDev ? "https://api.dev.bleudine.com" : isStaging ? "https://api.stg.bleudine.com" : "https://api.bleudine.com";
const bleudineSecret = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJiMGVlMGI2Yi02ZTc1LTRjZjMtODE0OC00NDE2OGY2ZDA3YzIiLCJqdGkiOiI1MjVkODg1Yi1jZjM4LTQ3M2ItOGE0Yi1mNWM0OGMxY2E5NDciLCJuYW1laWQiOiJhZG1pbkBibGV1ZGluZS5ieWNvZGVycy5jb20iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBZG1pbiIsImV4cCI6MTY0NTg1MTQxOCwiaXNzIjoiaHR0cDovL2JsZXVkaW5lLmNvbSIsImF1ZCI6Imh0dHA6Ly9ibGV1ZGluZS5jb20ifQ.sCdOn0TYZ0qeZqDQGlAYW8FyWbk8mSPE5DB-ER9xT1c";
const hasuraUrl = isLocalOrDev ? "https://hasura.dev.bleustay.com/v1/graphql" : isStaging ? "https://hasura.stg.bleustay.com/v1/graphql" : "https://hasura.bleustay.com/v1/graphql";
const hasuraSecret = isLocalOrDev ? "2drH5MsdDIsNzxlhdEIAuioyNuJtktQLxhhnLuGEqH11QWsBz0q5ce0dgqXVSCU5" : isStaging ? "gS0WpS6Fgl0FFFNbTAWO1CT5K5c1qOLNqqhH2iss7jsPwo2cnzyb52QpA4dYwWHf" : "Pp1pqMRPjzGlaCsjnShpmIenTdewdsUrfTzMW2TAI5E7o4baJ2zTmGHoZf3lVrDR";

const rideumClientId = isLocal ? "08dd3c44-52e9-453f-874d-ea9c3591b56b" : isDev ? "08dd5178-d793-4758-8ea7-d0d08402f53f" : isStaging ? "08dd5562-0f7e-4f4c-848e-408f422d0d84" : "08dd5564-4463-47ee-803c-e191fdf97bb8";
const loginBaseUrl = isLocal ? "http://localhost:3000/api/sso/auth" : isDev ? "https://subscription.dev.rideum.io/api/sso/auth" : isStaging ? "https://subscription.stg.rideum.io/api/sso/auth" : "https://subscription.rideum.io/api/sso/auth";
const rideumAuthBackendUrl = isLocal ? "http://localhost:50001" : isDev ? "https://admin.dev.bleudine.com": isStaging ? "https://admin.stg.bleudine.com" :"https://admin.bleudine.com";
const refreshTokenUrl = `${rideumAuthBackendUrl}/api/sso/token/refresh`
const codeExchangeUrl = `${rideumAuthBackendUrl}/api/sso/token`
const merchantProfileUrl = `${rideumAuthBackendUrl}/api/sso/auth/merchant`
const userProfileUrl = `${rideumAuthBackendUrl}/api/sso/auth/me`

export const apiConfig = {
    url: bleudineUrl,
    path: "/api",
    secret: bleudineSecret,
    getBleudineToken: function () {
        const user = JSON.parse(localStorage.getItem("bleudine-user"));
        return user ? user.token : "";
    },
    getRideumToken: function () {
        const token = localStorage.getItem("token");
        return token ? token : "";
    },

    hasuraUrl: hasuraUrl,
    hasuraSecret: hasuraSecret,
    rideumClientId,
    loginBaseUrl,
    refreshTokenUrl,
    codeExchangeUrl,
    merchantProfileUrl,
    userProfileUrl
};
