import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Title, useTranslate } from 'react-admin';
import * as XLSX from 'xlsx';
import { reportApi } from '../../api/reportApi';
import { AuthInfoContext } from '../../hooks/useAuthInfo';
import { formatter } from '../../utils/formatter';
import { useContext } from 'react';

export default function AccountBalanceReport() {
      const {authInfo} = useContext(AuthInfoContext);
    
    const translate = useTranslate();
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    // const [generated, setGenerated] = useState(false);
    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        generateEodReport()
    }, []);

    const exporter = () => {
        const first_data = [
            [`This is account balance report  generated on ${moment(selectedDate).format('YYYY-MM-DD')}`],
        ]

        const account_balance_data = reportData.map(record => {
            return {
                booking_no: record.booking_no,
                guest: record.guest,
                check_in_date: record.check_in_date,
                check_out_date: record.check_out_date,
                debits: formatter.formatMoney(
                    record.debits,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                credits: formatter.formatMoney(
                    record.credits,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                balance: formatter.formatMoney(
                    record.balance,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                )
            }
        });

        const account_balance_sheet = XLSX.utils.aoa_to_sheet(first_data)

        /* generate workbook and add the worksheet */
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, account_balance_sheet, 'Account_Balance');
        XLSX.utils.sheet_add_json(wb.Sheets.Account_Balance, account_balance_data, { origin: "A3" })

        /* save to file */
        XLSX.writeFile(wb, `Account_Balance_${moment(selectedDate).format('YYYY-MM-DD')}.xlsx`);
    };

    const generateEodReport = useMemo(() => async () => {
        // setGenerated(false)
        let date = moment(selectedDate)
        let tenantId = authInfo.tenant.id;
        const bookings = (await reportApi.bookingsByDate(date, tenantId)).data.bookings;
        const bookingIds = bookings.map(booking => booking.id)
        const orders = (await reportApi.getBookingsOrder(bookingIds)).data.orders;
        const payments = (await reportApi.getBookingsPayment(bookingIds)).data.payments;
        const refunds = (await reportApi.getBookingsRefund(bookingIds)).data.refunds;

        setReportData(bookings.map(booking => {
            let totalPayable = orders.filter(order => order.booking_id == booking.id).map(order => order.total_price).reduce((acc, cur) => acc + cur, 0)
            let totalCredits = payments.filter(payment => payment.booking_id == booking.id).map(payment => payment.amount).reduce((acc, cur) => acc + cur, 0)
            let totalRefunds = refunds.filter(refund => refund.booking_id == booking.id).map(refund => refund.amount).reduce((acc, cur) => acc + cur, 0)
            return {
                booking_no: booking.booking_no,
                check_in_date: booking.check_in_date,
                check_out_date: booking.check_out_date,
                guest: booking.customer_type == "Individual" ? `${booking.guest.first_name} ${booking.guest.last_name}` : booking.company.name,
                credits: totalCredits - totalRefunds,
                debits: totalPayable,
                balance: totalPayable + totalRefunds - totalCredits
            }
        }))
        // setGenerated(true)

    }, [selectedDate])

    return <Box padding={1} margin={2} height={"100%"}>
        <Title title={translate(`ra.label.account_balance_report`, { smart_count: 2 })}></Title>
        <Grid container direction="column">
            <Grid item>
                <Grid container direction="row" spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            style={{
                                marginTop: 12,
                            }}
                            type="date"
                            variant="filled"
                            value={moment(selectedDate).format('YYYY-MM-DD')}
                            onChange={(evt) => { setSelectedDate(evt.target.value) }}
                            fullWidth
                            label="Date"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            style={{
                                marginTop: 12,
                                marginRight: 5,
                            }}
                            onClick={generateEodReport}
                            variant="contained"
                            color="primary"
                        >
                            Search
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ marginLeft: 'auto', textAlignLast: 'end' }}>
                        <Button
                            style={{
                                marginTop: 12,
                                marginRight: 5,
                            }}
                            onClick={exporter}
                            variant="text"
                            color="primary"
                            endIcon={<DownloadIcon />}
                        >
                            Export
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid item>
                {/* <Typography>Account balance for {selectedDate}</Typography> */}
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Booking No</TableCell>
                                <TableCell>Guest</TableCell>
                                <TableCell>Check in date</TableCell>
                                <TableCell>Check out date</TableCell>
                                <TableCell>Debit</TableCell>
                                <TableCell>Credit</TableCell>
                                <TableCell>Balance</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.length > 0 ? reportData.map(record => <TableRow key={record.booking_no}>
                                <TableCell>{record.booking_no}</TableCell>
                                <TableCell>{record.guest}</TableCell>
                                <TableCell>{record.check_in_date}</TableCell>
                                <TableCell>{record.check_out_date}</TableCell>
                                <TableCell>{formatter.formatMoney(
                                    record.debits,
                                    authInfo.tenant.locale.currency_code,
                                    authInfo.tenant.locale.precision
                                )}</TableCell>
                                <TableCell>{formatter.formatMoney(
                                    record.credits,
                                    authInfo.tenant.locale.currency_code,
                                    authInfo.tenant.locale.precision
                                )}</TableCell>
                                <TableCell>{formatter.formatMoney(
                                    record.balance,
                                    authInfo.tenant.locale.currency_code,
                                    authInfo.tenant.locale.precision
                                )}</TableCell>

                            </TableRow>) : <TableRow><TableCell colSpan={5}>No record found</TableCell></TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>
        </Grid>
    </Box >
}
