import { Button, InputAdornment } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SearchIcon from "@material-ui/icons/Search";
import jsonExport from "jsonexport/dist";
import * as React from "react";
import { useCallback, useContext, useMemo } from "react";
import {
  Datagrid,
  EditButton,
  EmailField,
  FunctionField,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  downloadCSV,
} from "react-admin";
import { useHistory } from "react-router-dom";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { tenantStatus } from "../../models/tenantStatus";


const tenantFilters = [
  <TextInput
    source="email,name,phone_no,region,city,country"
    label="Search"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    style={{ width: 250 }}
    label="Status"
    source="status"
    reference="e_tenant_status"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
];

const exporter = (records) => {
  const forExport = records.map((record) => {
    return {
      name: record.name,
      email: record.email,
      phone_no: record.phone_no,
      country: record.e_country.comment,
      status: record.e_tenant_status.comment,
    };
  });
  jsonExport(
    forExport,
    {
      headers: ["name", "email", "phone_no", "country", "status"],
    },
    (err, csv) => {
      downloadCSV(csv, "tenants");
    }
  );
};

const TenantList = (props) => {
  const { authInfo, hasRole, updateAuth,hasOnlyRole } = useContext(AuthInfoContext);
  const history = useHistory();
  const tenant_group_id = useMemo(() => authInfo === undefined || authInfo.tenant_group_id === undefined ? "00000000-0000-0000-0000-000000000000" : authInfo.tenant_group_id, [authInfo]);

  const handleView = useCallback((tenant) => {
    updateAuth({ ...authInfo, 'tenant.id': tenant.id, tenant_id: tenant.id, tenant })
      .then(res => {
        if(hasOnlyRole("housekeeper")){
          window.location.href="/housekeeping"
        } else{
          window.location.href="/dashboard"
        }
      });
  }, [authInfo]);

  const defaultFilter = useMemo(() => {
    if (authInfo) {
      const ownerFilter = { tenant_group_id: tenant_group_id, is_deleted: false };
      if (authInfo.realm && authInfo.realm !== "AccountOwner") {
        return { ...ownerFilter, id: authInfo.properties }
      }
      return ownerFilter;
    } else {
      return {};
    }

  }, [authInfo,tenant_group_id]);

  return authInfo && <List
    sort={{ field: "created_at", order: "Desc" }}
    filters={tenantFilters}
    filterDefaultValues={defaultFilter}
    bulkActionButtons={false}
    exporter={exporter}
    {...props}
  >
    <Datagrid>
      <TextField source="name" label="ra.column.name" />
      <EmailField source="email" label="ra.column.email" />
      <TextField source="phone_no" label="ra.column.phone_no" />
      <TextField source="locale.currency_code" label="ra.column.currency" />
      <TextField source="city" label="ra.column.city" />
      <TextField source="e_country.comment" label="ra.column.country" />
      <FunctionField
        sortBy="status"
        label="Status"
        render={(record) => (
          <Chip
            label={record.e_tenant_status?.comment}
            style={{
              color: "white",
              background: tenantStatus.getColor(record.e_tenant_status?.id),
            }}
          />
        )}
      />
      {hasRole("manager") ? <EditButton style={{ float: "right" }} basePath="/tenants" /> : undefined}
      <FunctionField
        render={(record) => (
          <Button
            style={{ border: "none", float: "right" }}
            startIcon={<MenuBookIcon />}
            variant="outlined"
            color="primary"
            onClick={() => handleView(record)}
          >
            Manage
          </Button>
        )}
      />
    </Datagrid>
  </List>;
}

export default TenantList;
