import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";
const localhostPort = 64638;

export const bleudineMerchantApi = {
  getById,
};

function getById(id,access_token) { 
   const url = `${apiConfig.url}${apiConfig.path}/merchants/${id}/bleustay`;
   //const url = `https://localhost:54630/api/merchants/${id}/bleustay`;

   const requestOptions = {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json-patch+json",
    })
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((merchant) => {
      return merchant;
    });
 }

