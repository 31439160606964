import { Box, Card, CardContent, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";
import {
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  TextInput,
  Toolbar,
  email,
  maxLength,
  minLength,
  required,
  useTranslate,
} from "react-admin";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import CustomSaveButton from "./CustomSaveButton";

const validateFirstName = [required(), minLength(2), maxLength(25)];
const validateEmail = [required(), email()];

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const CMRoomTypeForm = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { source, ...rest } = props;
  const {authInfo} = useContext(AuthInfoContext);

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    {translate("ra.label.client_info")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="cm_client_id"
                    reference="cm_clients"
                    sort={{ field: "name", order: "ASC" }}
                    required
                    label="ra.label.client_name"
                    fullWidth
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    filter={{ tenant_id: authInfo.tenant.id, status: "Active" }}
                    source="room_type_id"
                    reference="room_types"
                    sort={{ field: "name", order: "ASC" }}
                    label="ra.label.room_types"
                    required
                    fullWidth
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    source="cm_client_room_type_name"
                    resource="cm_room_type_maps"
                    required
                    label="Client Room Type Name"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CustomSaveButton {...formProps} />
                {/* {formProps.record.id != undefined ?
                  <DeleteButton record={formProps.record} />
                  : null} */}
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default CMRoomTypeForm;
