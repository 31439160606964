import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import BusinessInsight from "../BusinessInsight";


const Dashboard = () => {
    const { authInfo, hasOnlyRole } = useContext(AuthInfoContext);
    let history = useHistory();
    const [loading, setLoading] = useState(false);

    const noTenantSelected = useMemo(() => {
        return !authInfo.tenant_id
    }, [authInfo.tenant_id]);

    useEffect(() => {
        setLoading(true);
        if (!noTenantSelected && hasOnlyRole("housekeeper")) {
            history.push("/housekeeping");
        } else if (noTenantSelected) {
            history.push("/tenants");
        } else {
            setLoading(false);
        }
    }, [noTenantSelected]);

    return !authInfo || loading ? <div></div> : <BusinessInsight />;
}

export default Dashboard;
