import { Box, Button, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  DateInput,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  required,
  useTranslate
} from "react-admin";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";

import { DataProviderContext, useNotify, useRedirect } from "react-admin";
import { authApi } from "../../api/authApi";
import { deliveryApi } from "../../api/deliveryApi";
import { tenantGroupApi } from "../../api/tenantGroupApi";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import imgRepairService from "../../images/repair_service.jpg";
import ImageUploader from "../components/ImageUploader";
import CustomSaveButton from './CustomSaveButton';


const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const RideumServicePane = ({ child }) => {
  return (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <div style={{ flexBasis: '6%' }}>
      <img src={imgRepairService} style={{ width: 50, height: 50 }} />
    </div>
    <div>
      {child}
    </div>
  </div>);
}

const MaintenanceForm = (props) => {
  const {authInfo} = useContext(AuthInfoContext);
  
  const classes = useStyles();
  const translate = useTranslate();
  const { source, ...rest } = props;
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useContext(DataProviderContext);
  const [images, setImages] = React.useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [rideumConnected, setRideumConnected] = useState(false);
  const [sendToRideum, setSendToRideum] = useState(false);

  useEffect(async () => {
    const tenant = await tenantGroupApi.getTenantGroupIdAsync(authInfo.tenant.id);
    if (tenant.errors) throw new Error(merchant.errors[0].message);

    const merchant = await tenantGroupApi.getRideumMerchantAsync(tenant.data.tenant_group_id);
    if (merchant.errors) notify(merchant.errors[0].message);
    setRideumConnected(merchant.data && merchant.data.id);

    if (props.record != null) {
      setImages(props.record.images_url?.map(element => {
        return { data_url: element }
      }));
    }
  }, []);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmDelete = async (id, refDeliveryId) => {
    const token = await authApi.getSystemTokenAsync();
    if (token.errors) throw new Error(token.errors[0].message);

    let successCancel = false;

    if (refDeliveryId != null) {
      const cancelDelivery = await deliveryApi.cancelDeliveryAsync(token.data, refDeliveryId);
      if (cancelDelivery.errors) {
        notify(`Unable to delete due to ${cancelDelivery.errors[0].message}`);
      }
      else {
        successCancel = true;
      }
    }
    else {
      successCancel = true;
    }

    if (successCancel == true) {
      dataProvider
        .delete("maintenances", { id: id })
        .then(async ({ data }) => {
          setOpenDelete(false);
          notify("Maintenance deleted");
          redirect(`/maintenances`);
        })
        .catch((error) => {
          notify(error);
        });
    }
  };

  const handleChange = () => setSendToRideum(!sendToRideum);
  const isNewJob = props.record.id === undefined;
  const isJobSendToRideum = props.record.refDeliveryId !== undefined;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Maintenance Info
                  </Typography>
                  {
                    isJobSendToRideum ?
                      <Box component="span" style={{ color: 'red' }}>
                        * This maintenance has send to Bleustay Service
                      </Box>
                      : null
                  }
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={classes.formGrid}
                  style={{ display: "none" }}
                >
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    source="tenant_id"
                    label="Tenant ID"
                    defaultValue={authInfo.tenant.id}
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    source="title"
                    label="Title"
                    validate={[required()]}
                    disabled={isJobSendToRideum}
                  />
                </Grid>
                <Grid item xs={12} sm={12} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    fullWidth
                    label="Room"
                    source="room_id"
                    reference="rooms"
                    filter={{ tenant_id: authInfo.tenant.id }}
                    sort={{ field: "name", order: "ASC" }}
                    filterToQuery={(searchText) => ({
                      name: searchText,
                    })}
                    disabled={isJobSendToRideum}
                  >
                    <AutocompleteInput
                      optionText="name"
                      options={{ InputProps: { autoComplete: "no" } }}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <DateInput
                    className={classes.formInput}
                    fullWidth
                    source="start_date"
                    type="date"
                    label="Start Date"
                    disabled={isJobSendToRideum}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <DateInput
                    className={classes.formInput}
                    fullWidth
                    source="end_date"
                    type="date"
                    label="End Date"
                    disabled={isJobSendToRideum}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <ReferenceInput
                    className={classes.formInput}
                    fullWidth
                    resettable
                    label="Priority Level"
                    source="priority_level"
                    reference="e_priority_levels"
                    disabled={isJobSendToRideum}
                  >
                    <SelectInput optionText="comment" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                  <Card><CardContent>
                    <ArrayInput
                      label="Jobs" resource="maintenances"
                      source="maintenance_jobs"
                      disabled={isJobSendToRideum}
                    >
                      <SimpleFormIterator>
                        <TextInput
                          fullWidth
                          source="job"
                          label="Job Description"
                          validate={[required()]}
                          disabled={isJobSendToRideum}
                        />
                        <NumberInput
                          source="quantity"
                          label="Quantity"
                          min={1}
                          defaultValue={1}
                          validate={[required(),]}
                          disabled={isJobSendToRideum}
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </CardContent></Card>
                </Grid>
                <Grid item xs={12}>
                  <Card><CardContent>
                    <Typography style={{ marginTop: 10, color: "#000000", opacity: "0.5" }} variant="caption" display="block" gutterBottom>
                      Images
                    </Typography>
                    <ImageUploader
                      maxNumber={30}
                      images={images}
                      setImages={setImages}
                      disabled={isJobSendToRideum}
                    />
                  </CardContent></Card>
                </Grid>
                {
                  isNewJob && rideumConnected ?
                    <Grid item xs={12}>
                      <Card><CardContent>
                        <RideumServicePane child={
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sendToRideum}
                                onChange={handleChange}
                                name="syncRideum"
                                color="secondary"
                              />
                            }
                            label="Send this maintenance to Bleustay Service"
                          />
                        } />
                      </CardContent></Card>
                    </Grid>
                    : null
                }
                {
                  !isNewJob && formProps.record.refDeliveryId !== undefined ?
                    <Grid item xs={12}>
                      <Card><CardContent>
                        <RideumServicePane child={
                          <a
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            href={`https://merchant.dev.bleudash.com/tracking/${formProps.record.refDeliveryId}`}>
                            Click here to open and track your maintenance at <strong>Bleustay Service</strong>
                          </a>
                        } />
                      </CardContent></Card>
                    </Grid>
                    : null
                }
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                {
                  formProps.record.refDeliveryId === undefined ?
                    <CustomSaveButton {...formProps} syncRideum={sendToRideum} images={images} />
                    : null
                }
                {formProps.record.id != undefined ? (
                  <Button
                    style={{ color: "red" }}
                    variant="outline"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={handleOpenDelete}
                  >
                    Delete
                  </Button>
                ) : null}
                <Dialog
                  open={openDelete}
                  onClose={handleCloseDelete}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Confirm Delete?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Please confirm to delete
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseDelete()} color="primary">
                      Cancel
                    </Button>
                    <Button
                      onClick={() => handleConfirmDelete(formProps.record.id, formProps.record.refDeliveryId)}
                      color="primary"
                      autoFocus
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default MaintenanceForm;
