import React, { useContext } from "react";
import {
  FormWithRedirect,
  DateInput,
  TextInput,
  SelectInput,
  SaveButton,
  DeleteButton,
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  email,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
} from "react-admin";
import { Typography, Box } from "@material-ui/core";
import { IntlPhoneInput } from "../components/IntlPhoneInput";
import { Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { AuthInfoContext } from "../../hooks/useAuthInfo";


const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const RoomForm = (props) => {
  const {authInfo} = useContext(AuthInfoContext);
  const classes = useStyles();
  const translate = useTranslate();
  const { source, ...rest } = props;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Room Info
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="room_type_id"
                    reference="room_types"
                    filter={{tenant_id: authInfo.tenant.id}}
                    sort={{ field: "name", order: "ASC" }}
                    filterToQuery={(searchText) => ({
                      name: searchText,
                    })}
                    label="Type"
                    fullWidth
                  >
                    <AutocompleteInput
                      optionText="name"
                      options={{ InputProps: { autoComplete: "no" } }}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="rooms"
                    source="floor"
                    fullWidth
                    validate={[required(), minLength(1), maxLength(25)]}
                  />
                </Grid>
                <Grid item xs={12} sm={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="rooms"
                    source="name"
                    fullWidth
                    validate={[required(), minLength(2), maxLength(25)]}
                  />
                </Grid>
                <Grid item xs={12} sm={12} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    fullWidth
                    label="Status"
                    source="status"
                    reference="e_room_status"
                    validate={[required()]}
                  >
                    <SelectInput optionText="comment" />
                  </ReferenceInput>
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton {...formProps} />
                {/* {formProps.record.id != undefined ?
                  <DeleteButton record={formProps.record} />
                  : null} */}
              </Box>
            </Toolbar>
            {/* <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmitWithRedirect}
              saving={formProps.saving}
              resource="rooms"
            /> */}
          </form>
        </Card>
      )}
    />
  );
};

export default RoomForm;
