import React, { useContext } from 'react';
import { SaveButton } from 'react-admin';
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect } from 'react-admin';
import { DataProviderContext } from 'react-admin';
import { authApi } from "../../api/authApi";
import { imageApi } from "../../api/imageApi";
import { locationApi } from "../../api/locationApi";
import { deliveryApi } from "../../api/deliveryApi";
import { tenantGroupApi } from "../../api/tenantGroupApi";
import { maintenanceApi } from "../../api/maintenanceApi";
import { uuid } from "../../utils/uuid";
import { AuthInfoContext } from '../../hooks/useAuthInfo';


const CustomSaveButton = (formProps) => {
	const {authInfo} = useContext(AuthInfoContext);
	const notify = useNotify();
	const redirect = useRedirect();
	const dataProvider = useContext(DataProviderContext);
	let { values } = useFormState();
	const [saving, setSaving] = React.useState(false);

	var request = {
		...values,
		maintenance_jobs: values.maintenance_jobs != undefined ? { data: values.maintenance_jobs } : null,
	}

	const handleCreateClick = async () => {
		setSaving(true);

		const images = formProps.images || [];
		let imgsUrl = [];
		let maintenanceId;

		for (let img of images) {
			const upload = await imageApi.uploadAsync({
				file: img.data_url.split(",")[1],
				fileName: uuid.create_UUID()
			});

			if (upload.errors) {
				setSaving(false);
				notify(upload.errors[0].message);
			}

			imgsUrl.push(upload.data.imageUrl);
		}

		if (!formProps.images || formProps.images.length == imgsUrl.length) {
			request.images_url = imgsUrl.length > 0 ? `{${imgsUrl.toString()}}` : `{}`;
			dataProvider.create(formProps.resource, { data: { ...request } })
				.then(({ data }) => {
					maintenanceId = data.id;
				})
				.catch(error => {
					setSaving(false);
					notify(error);
				});
		} else {
			setSaving(false);
			notify("Error when upload image");
		};

		if (formProps.syncRideum) {
			const street = `${authInfo.tenant.address_1}, ${authInfo.tenant.address_2}`;
			const district = authInfo.tenant.region;
			const zipcode = authInfo.tenant.zip_code;
			const city = authInfo.tenant.city;
			const country = authInfo.tenant.country;

			try {
				const tenant = await tenantGroupApi.getTenantGroupIdAsync(authInfo.tenant.id);
				if (tenant.errors) throw new Error(merchant.errors[0].message);

				const tenant_group_id = tenant.data.tenant_group_id;
				const merchant = await tenantGroupApi.getRideumMerchantAsync(tenant_group_id);
				if (merchant.errors) throw new Error(merchant.errors[0].message);
				if (merchant.data && merchant.data.id) {
					const token = await authApi.getSystemTokenAsync();
					if (token.errors) throw new Error(token.errors[0].message);

					const location = await locationApi.getPickupLocationAsync(token.data, merchant.data.id, street, district, zipcode, city, country);
					if (location.errors) throw new Error(location.errors[0].message);

					const pickupLocationId = location.data.id;
					const merchantId = merchant.data.id;
					const merchantName = merchant.data.name;
					const currencyCode = authInfo.tenant.locale.currency_code;
					const priorityLevel = request.priority_level;
					const images = imgsUrl.map(img => ({ imageUrl: img }));

					const deliveryItems = request.maintenance_jobs.data.map(job => ({
						itemName: job.job,
						quantity: job.quantity,
					}));

					const customer = {
						name: authInfo.tenant.name,
						phoneNumber: authInfo.tenant.phone_no,
						customerAddress: {
							street: location.data.street,
							district: location.data.district,
							city: location.data.city,
							zipCode: location.data.zipCode,
							country: location.data.country,
							longitude: location.data.longitude,
							latitude: location.data.latitude,
						}
					};

					const delivery = await deliveryApi.createDeliveryAsync(token.data,
						pickupLocationId,
						priorityLevel,
						merchantId,
						merchantName,
						currencyCode,
						customer,
						deliveryItems,
						images);
					if (delivery.errors) {
						throw new Error(delivery.errors[0].message);
					} else {
						const refDeliveryId = delivery.data.id;
						await maintenanceApi.updateRefDeliveryIdAsync(maintenanceId, refDeliveryId);

						redirect(`/maintenances`);
						notify("Maintenance created");
					}
				}
				else {
					throw new Error("Please connect to Bleustay Service");
				}
			} catch (err) {
				setSaving(false);
				notify(err.message);
			}
		} else {
			redirect(`/maintenances`);
			notify("Maintenance created");
		}
	}


	const handleUpdateClick = () => {
		setSaving(true);
		var existingImgs = formProps.record.images_url;
		if (existingImgs) {
			existingImgs.forEach((exImg) => {
				if (formProps.images?.filter(e => e.data_url.includes(exImg)).length == 0) {
					var splitExImg = exImg.split("/");
					imageApi.remove({
						fileName: splitExImg[splitExImg.length - 1]
					}).then(
						(data) => { },
						(error) => {
							console.log(error);
						})
				}
			})
		}

		var promises = [];
		let imgsUrl = [];

		formProps.images?.forEach(function (img) {
			if (img.data_url.includes("data")) {
				promises.push(
					imageApi.upload({
						file: img.data_url.split(",")[1],
						fileName: uuid.create_UUID()
					}).then(
						(data) => {
							imgsUrl.push(data.imageUrl);
						},
						(error) => {
							console.log(error);
						})
				);
			} else {
				imgsUrl.push(img.data_url);
			}
		});

		Promise.all(promises).then(() => {

			//===========================================================
			//maintenance_jobs
			let maintenance_jobs = request.maintenance_jobs.data;
			let maintenance_jobs_id = maintenance_jobs.map(function (item) {
				return item.id
			});
			let existing_maintenance_jobs_id = formProps.record.maintenance_jobs.map(function (item) {
				return item.id
			});

			//delete maintenance_jobs
			existing_maintenance_jobs_id.forEach(element => {
				if (maintenance_jobs_id.indexOf(element) === -1) {
					dataProvider.delete("maintenance_jobs", { id: element })
				}
			})
			if (maintenance_jobs.length > 0) {
				maintenance_jobs.forEach(element => {
					if (element.id !== undefined) {
						//update room type rate
						dataProvider.update("maintenance_jobs", { id: element.id, data: { ...element } })
							.catch(error => {
								setSaving(false);
								notify(error);
							})
					} else {
						//create room type rate
						dataProvider.create("maintenance_jobs", { data: { ...element, maintenance_id: request.id, tenant_id: authInfo.tenant.id } })
							.catch(error => {
								setSaving(false);
								notify(error);
							})
					}
				})
			}

			//===========================================================

			if (!formProps.images || formProps.images.length == imgsUrl.length) {
				request.images_url = imgsUrl.length > 0 ? `{${imgsUrl.toString()}}` : `{}`;
				dataProvider.update(formProps.resource, { id: request.id, data: { ...request } })
					.then(({ data }) => {
						redirect(`/maintenances`);
						notify("Maintenance updated");
					})
					.catch(error => {
						setSaving(false);
						notify(error);
					})
			} else {
				setSaving(false);
				notify("Error when upload image");
			};
		})

	}


	return <SaveButton {...formProps} saving={saving} disabled={saving} onSave={values.id != undefined && values.refDeliveryId === undefined ? handleUpdateClick : handleCreateClick} />;
};

export default CustomSaveButton;