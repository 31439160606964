import { useEffect } from "react";
import { Datagrid, List, RefreshButton, TextField, TopToolbar, useRefresh } from "react-admin";
import { AuthInfoContext } from "../../hooks/useAuthInfo";
import { useContext } from "react";

const ListActions = ()=>{
    return <TopToolbar>
        <RefreshButton/>
    </TopToolbar>
}


export default function ChannelManagerSyncTaskList(props) {
    const {authInfo} = useContext(AuthInfoContext);
    const refresh = useRefresh();
    useEffect(()=>{
        let intervalHandler = window.setInterval(()=>{
            refresh();
        },5000);


        return ()=>{
            window.clearInterval(intervalHandler);
        }
    },[]);
    return <List {...props} exporter={false}
        filterDefaultValues={{ tenant_id: authInfo.tenant.id }} bulkActionButtons={false} actions={<ListActions/>}>
        <Datagrid>
            <TextField source="cm_client.name" label={"OTA"} />
            <TextField source="description" label={"Description"} />
            <TextField source="status" label={"Status"} />
            <TextField source="remark" label="Remark" />
        </Datagrid>
    </List>
}