import React, { useContext } from 'react';
import {
  SaveButton
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect } from 'react-admin';
import { DataProviderContext } from 'react-admin';
import { imageApi } from "../../api/imageApi";
import { uuid } from "../../utils/uuid";
import { AuthInfoContext } from '../../hooks/useAuthInfo';


const CustomSaveButton = (formProps) => {
  const {authInfo} = useContext(AuthInfoContext);
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useContext(DataProviderContext);
  let { values } = useFormState();
  const [saving, setSaving] = React.useState(false);

  //massage data
  var request = {
    ...values,
    room_type_beds: values.room_type_beds != undefined ? { data: values.room_type_beds } : null,
    room_facilities: values.room_facilities != undefined ? `{${values.room_facilities?.toString()}}` : `{}`,
    bathroom_facilities: values.bathroom_facilities != undefined ? `{${values.bathroom_facilities?.toString()}}` : `{}`,
    view_facilities: values.view_facilities != undefined ? `{${values.view_facilities?.toString()}}` : `{}`,
    bedroom_facilities: values.bedroom_facilities != undefined ? `{${values.bedroom_facilities?.toString()}}` : `{}`,
    entertainment_facilities: values.entertainment_facilities != undefined ? `{${values.entertainment_facilities?.toString()}}` : `{}`,
    kitchen_facilities: values.kitchen_facilities != undefined ? `{${values.kitchen_facilities?.toString()}}` : `{}`,
    outdoor_facilities: values.outdoor_facilities != undefined ? `{${values.outdoor_facilities?.toString()}}` : `{}`,
    floor_facilities: values.floor_facilities != undefined ? `{${values.floor_facilities?.toString()}}` : `{}`,
    tenant_id: authInfo.tenant.id
  }

  const handleCreateClick = () => {
    setSaving(true);
    var promises = [];
    var errors = [];
    let imgsUrl = [];
    formProps.images?.forEach(function (img) {
      promises.push(
        imageApi.upload({
          file: img.data_url.split(",")[1],
          fileName: uuid.create_UUID()
        }).then(
          (data) => {
            imgsUrl.push(data.imageUrl);
          },
          (error) => {
            console.log(error);
          })
      );
    });

    Promise.all(promises).then(() => {
      if (!formProps.images || formProps.images.length == imgsUrl.length) {
        request.images_url = imgsUrl.length > 0 ? `{${imgsUrl.toString()}}` : `{}`;
        dataProvider.create(formProps.resource, { data: { ...request } })
          .then(({ data }) => {
            redirect(`/room_types`);
            notify("Room type created");
          })
          .catch(error => {
            setSaving(false);
            let message = "Something went wrong.";

            if (error?.message.includes("duplicate") && error?.message.includes("room_types_name_tenant_id_key")) {
              message = "Fail to create room type, name already been used.";
            }
  
            notify(message, 'error');
          })
      } else {
        setSaving(false);
        notify("Error when upload image");
      };
    }
    );


  }

  const handleUpdateClick = () => {
    setSaving(true);
    var existingImgs = formProps.record.images_url;
    if(existingImgs){
      existingImgs.forEach((exImg) => {
        if (formProps.images?.filter(e => e.data_url.includes(exImg)).length == 0) {
          var splitExImg = exImg.split("/");
          imageApi.remove({
            fileName: splitExImg[splitExImg.length - 1]
          }).then(
            (data) => { },
            (error) => {
              console.log(error);
            })
        }
      })
    }

    var promises = [];
    var errors = [];
    let imgsUrl = [];
    formProps.images?.forEach(function (img) {
      if (img.data_url.includes("data")) {
        promises.push(
          imageApi.upload({
            file: img.data_url.split(",")[1],
            fileName: uuid.create_UUID()
          }).then(
            (data) => {
              imgsUrl.push(data.imageUrl);
            },
            (error) => {
              console.log(error);
            })
        );
      } else {
        imgsUrl.push(img.data_url);
      }
    });

    Promise.all(promises).then(() => {

      //===========================================================
			//room_type_beds
			let room_type_beds = request.room_type_beds.data;
			let room_type_beds_id = room_type_beds.map(function (item) {
				return item.id
			});
			let existing_room_type_beds_id = formProps.record.room_type_beds.map(function (item) {
				return item.id
			});

			//delete room_type_beds
			existing_room_type_beds_id.forEach(element => {
				if (room_type_beds_id.indexOf(element) === -1) {
					dataProvider.delete("room_type_beds", { id: element })
				}
			})
			if (room_type_beds.length > 0) {
				room_type_beds.forEach(element => {
					if (element.id !== undefined) {
						//update room_type_beds
						dataProvider.update("room_type_beds", { id: element.id, data: { ...element } })
							.catch(error => {
								setSaving(false);
								notify(error);
							})
					} else {
						//create room_type_beds
						dataProvider.create("room_type_beds", { data: { ...element, room_type_id: request.id, tenant_id: authInfo.tenant.id } })
							.catch(error => {
								setSaving(false);
								notify(error);
							})
					}
				})
			}

			//===========================================================

      if (!formProps.images || formProps.images.length == imgsUrl.length) {
        request.images_url = imgsUrl.length > 0 ? `{${imgsUrl.toString()}}` : `{}`;
        dataProvider.update(formProps.resource, { id: request.id, data: { ...request } })
          .then(({ data }) => {
            redirect(`/room_types`);
            notify("Room type updated");
          })
          .catch(error => {
            setSaving(false);
            let message = "Something went wrong.";

            if (error?.message.includes("duplicate") && error?.message.includes("room_types_name_tenant_id_key")) {
              message = "Fail to update room type, name already been used.";
            }
  
            notify(message, 'error');
          })
      } else {
        setSaving(false);
        notify("Error when upload image");
      };
    })

  }

  return <SaveButton {...formProps} saving={saving} disabled={saving} onSave={values.id != undefined ? handleUpdateClick : handleCreateClick} />;
};

export default CustomSaveButton;