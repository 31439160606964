import { useEffect } from "react";
import { Loading } from "react-admin";
import { merchantProfile } from "./rideumAuthProvider";
import { AuthInfoContext } from "../hooks/useAuthInfo";
import { useContext } from "react";

async function completeAuth(setAuthInfo) {
    const status = window.localStorage.getItem("token_status");
    let retryAttempt = parseInt(window.localStorage.getItem("complete_auth_attempt") || '0');
    window.localStorage.setItem("complete_auth_attempt", (retryAttempt + 1) + "");
    if (retryAttempt < 3 && (!status || status === "pending")) {
        setTimeout(() => completeAuth(setAuthInfo), 500);
    } else if (status === "completed") {
        merchantProfile().then(userInfo => {
            if (userInfo) {
                window.localStorage.removeItem("complete_auth_attempt");
                window.localStorage.removeItem("token_status");
                setAuthInfo(userInfo).then(() => {

                    window.location.href = "/";
                });
            }
        })
    }
};

export default function OAuthCallback() {
    const { updateAuth }  = useContext(AuthInfoContext);
    useEffect(() => {
        completeAuth((info) => { return updateAuth(info) });
    }, [updateAuth]);
    return <div>
        <Loading loadingPrimary={"Please wait"} />
    </div>
}
