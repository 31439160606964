import React, { useContext } from 'react';
import {
  FormWithRedirect,
  TextInput,
  NumberInput,
  SelectInput,
  ImageInput,
  ImageField,
  DeleteButton,
  required,
  minLength,
  maxLength,
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  CheckboxGroupInput,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  ReferenceArrayInput,
  SaveButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Typography, Box, Select, FormGroup, Checkbox, FormControlLabel, InputAdornment } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect } from 'react-admin';
import { DataProviderContext } from 'react-admin';
import CustomCreateButton from './CustomCreateButton';
import { AuthInfoContext } from '../../hooks/useAuthInfo';


const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  // groupLabel: {
  //   color: "rgba(0, 0, 0, 0.54)",
  //   fontSize: "14px"
  // }
}));

const RoomCreateForm = props => {
  const {authInfo} = useContext(AuthInfoContext);
  const classes = useStyles();
  const translate = useTranslate();
  const { source, ...rest } = props;

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.textBold} gutterBottom>
                    Room Info
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="room_type_id"
                    reference="room_types"
                    filter={{ tenant_id: authInfo.tenant.id }}
                    filterToQuery={(searchText) => ({
                      name: searchText,
                    })}
                    label="ra.column.type"
                    fullWidth
                    validate={[required()]}
                  >
                    <AutocompleteInput
                      optionText="name"
                      options={{ InputProps: { autoComplete: "no" } }}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="rooms"
                    source="floor"
                    validate={[required(), minLength(1), maxLength(25)]}
                    label="Floor"
                  />
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <Card>
                    <CardContent>
                      <ArrayInput label="Rooms" resource="rooms" source="room_rooms" className={classes.formInput}>
                        <SimpleFormIterator>
                          <TextInput source="name" label="Name" validate={[required()]} />
                        </SimpleFormIterator>
                      </ArrayInput>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CustomCreateButton {...formProps} />
              </Box>
            </Toolbar>
          </form>
        </Card>

      )}
    />
  )
}

export default RoomCreateForm;